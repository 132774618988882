import React, { useState, useEffect } from "react";
import { Alert, Card, TextField, Stack, Button, Box } from "@mui/material";
import { auth, db } from "../config/firebase.js";
import {
  collection,
  doc,
  where,
  query,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import Login from "./Login.js";
import ProCorpPrivatePollAdmin from "./ProCorpPrivatePollAdmin.js"
import ProCorpPrivatePollUser from "./ProCorpPrivatePollUser.js"
import ProCorpPrivateQuizAdmin from "./ProCorpPrivateQuizAdmin.js"
import ProCorpPrivateQuizUser from "./ProCorpPrivateQuizUser.js"
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Tester from "./Tester.js"

function ProCorpPrivate({ userId, onLogin, onLogout }) {
  const [corpPoll, setCorpPoll] = useState(true)
  const [corpQuiz, setCorpQuiz] = useState(false)
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState('');
  const [userType, setUserType] = useState("user");
  const [showAlert, setShowAlert] = useState(false);

  const [companyIdError, setCompanyIdError] = useState(false);
  const [showEnterCompanyId, setShowEnterCompanyId] = useState(true);
  const [correctCompanyIdEntered, setCorrectCompanyIdEntered] = useState(false);

  const button1Color = "#5B76D7";
  const button2Color = "#f5f5f5";
  const buttonSelectedColor = "#af52bf";
  const [selectedButton, setSelectedButton] = useState(1);

  useEffect(() => {
    const fetchUserType = async () => {
      if (userId) {
        await checkUserType(userId);
      }
    };
    fetchUserType()
  }, [
    userId,
    companyId,
    selectedButton,
    corpPoll,
    corpQuiz,
    correctCompanyIdEntered,
    showEnterCompanyId,
  ]);

  const checkIfCompanyIdCorrect = async (companyId) => {
    try {
      const docRef = doc(db, "corpTable", companyId);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.log("Failed...", companyId, docSnapshot.id);
        return false;
      }
      setCompany(docSnapshot.data().name)

      return true;
    } catch (error) {
      console.error("Error checking document:", error);
      return false;
    }
  };

  const handleCompanyIdEntered = async () => {

    const isCompanyIdCorrect = await checkIfCompanyIdCorrect(companyId);
    if (!isCompanyIdCorrect) {
      setCompanyId("");
      return setCompanyIdError(true);
    }

    // setCompanyId("");
    setShowEnterCompanyId(false);
    setCompanyIdError(false);
    setSelectedButton(1);
    setCorrectCompanyIdEntered(true);
  };

  // const handlePrivateClicked = () => {
  //     setShowEnterCompanyId(!showEnterCompanyId)
  //     setCompanyIdError(false);
  //     setSelectedButton(0);
  //     setSelectedButton(selectedButton === 1 ? null : 1);
  // };

  const handleClose = () => {
    setCompanyIdError(false);
  };

  const checkUserType = async (userId) => {

    if (userId != null) {
      try {
        const overviewQuery = query(
          collection(db, "corpTable"),
          where("name", "==", company),
          where("admin", "==", userId)
        );

        const overviewSnapshot = await getDocs(overviewQuery);

        if (overviewSnapshot.empty) {
          setUserType("user");
        } else {
          setUserType("admin");
        }
      } catch (error) {
        console.error("Firebase error: ", error);
        setUserType('')
      }
    } else setUserType('')
  };

  function renderCompany() {
    return (<Card
      elevation={5}
      sx={{
        width: "334px",
        height: '40px',
        marginTop: "1em",
        marginBottom: "1em",
        marginLeft: "6px",
        marginRight: "6px",
        fontSize: '16px',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        color: '#af52bf',
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
      }}
    >
      {company}
    </Card>
    )
  }
  function handlePolls() {
    setCorpPoll(true)
    setCorpQuiz(false)
    if (!(selectedButton === 1)) {
      setSelectedButton(1);
    } else {
      setSelectedButton(0);
    }
  }
  function handleQuizzes() {
    setCorpPoll(false)
    setCorpQuiz(true)
    if (!(selectedButton === 2)) {
      setSelectedButton(2);
    } else {
      setSelectedButton(0);
    }
  }
  function corpPollorQuiz() {
    return (
      <>
        {/* <Card
          elevation={10}
          sx={{
            width: "320px",
            height: "60px",
            marginTop: "1em",
            marginBottom: "1em",
            marginLeft: "6px",
            // marginRight: "4px",
          }}
        > */}
        <Stack direction='row'>
          <Button
            variant="contained"
            backgroundcolor="#333"
            onClick={() => {
              handlePolls()
            }}
            style={{
              border: "none",
              width: "165px",
              height: "40px",
              marginLeft: "6px",
              marginTop: "4px",
              marginBottom: "4px",
              borderRadius: 7,
              fontWeight: "bold",
              backgroundColor:
                selectedButton === 1 ? buttonSelectedColor : button2Color,
              color: selectedButton === 1 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              textTransform: "none",
              //backgroundColor: "#5B76D7",
              // backgroundColor: "#D1D1D1",
            }}
          >
            POLLS
          </Button>
          <Button
            variant="contained"
            backgroundcolor="#333"
            onClick={() => {
              handleQuizzes()
            }}
            style={{
              border: "none",
              width: "165px",
              height: "40px",
              marginLeft: "4px",
              marginRight: "10px",
              marginTop: "4px",
              marginBottom: "4px",
              borderRadius: 7,
              fontWeight: "bold",
              // color: "white",
              backgroundColor:
                selectedButton === 2 ? buttonSelectedColor : button2Color,
              color: selectedButton === 2 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              textTransform: "none",
              //backgroundColor: "#5B76D7",
              // backgroundColor: "#D1D1D1",
            }}
          >
            QUIZZES
          </Button>
        </Stack>
        {/* </Card > */}
      </>
    )
  }

  return (
    <Box>
      <Card
        elevation={10}
        sx={{
          width: "334px",
          marginTop: "1em",
          marginBottom: ".25em",
          marginLeft: "8px",
          marginRight: "4px",
        }}
      >
        {showEnterCompanyId ? (
          <div>

            <TextField
              label="Company ID"
              variant="outlined"
              sx={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
            />
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              style={{
                border: "none",
                width: "100px",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: 28,
                color: "black",
                backgroundColor: "#D1D1D1",
              }}
              onClick={handleCompanyIdEntered}
            >
              Save
            </Button>

          </div>
        ) : (null)
        }

        {showEnterCompanyId && companyIdError && (
          <Alert
            severity="error"
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          >
            <span style={{ fontStyle: "italic" }}>
              Enter the correct Company ID, or contact your adminstrator
            </span>
          </Alert>
        )}
      </Card>

      {correctCompanyIdEntered && !userId && (
        <Login userId={userId} onLogin={onLogin} landingPage={'private'} />)}

      {correctCompanyIdEntered && userId && (
        <>
          {renderCompany()}
          {corpPoll && (
            userType === 'admin' ?
              <ProCorpPrivatePollAdmin userId={userId} db={db} company={company} /> :
              <ProCorpPrivatePollUser userId={userId} db={db} company={company} />)}
        </>
      )}
    </Box>
  );
}

export default ProCorpPrivate;
