import React, { useState, useEffect } from "react";
import { Alert, Card, TextField, Stack, Button, Box } from "@mui/material";
import { auth, db } from "../config/firebase.js";
import {
  collection,
  doc,
  where,
  query,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import Login from "./Login.js";
import ProCorpPrivatePollAdmin from "./ProCorpPrivatePollAdmin.js"
import ProCorpPrivatePollUser from "./ProCorpPrivatePollUser.js"
import ProCorpPrivateQuizAdmin from "./ProCorpPrivateQuizAdmin.js"
import ProCorpPrivateQuizUser from "./ProCorpPrivateQuizUser.js"
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Tester from "./Tester.js"

function ProEducation({ userId, onLogin, onLogout }) {
  const [corpPoll, setCorpPoll] = useState(true)
  const [corpQuiz, setCorpQuiz] = useState(false)
  const [institutionId, setInstitutionId] = useState("");
  const [institution, setInstitution] = useState('');
  const [userType, setUserType] = useState("user");
  const [showAlert, setShowAlert] = useState(false);

  const [institutionIdError, setInstitutionIdError] = useState(false);
  const [showEnterInstitutionId, setShowEnterInstitutionId] = useState(true);
  const [correctInstitutionIdEntered, setCorrectInstitutionIdEntered] = useState(false);

  const button1Color = "#5B76D7";
  const button2Color = "#f5f5f5";
  const buttonSelectedColor = "#af52bf";
  const [selectedButton, setSelectedButton] = useState(1);

  useEffect(() => {
    const fetchUserType = async () => {
      if (userId) {
        await checkUserType(userId);
      }
    };
    fetchUserType()
  }, [
    userId,
    institutionId,
    selectedButton,
    corpPoll,
    corpQuiz,
    correctInstitutionIdEntered,
    showEnterInstitutionId,
  ]);

  const checkIfInstitutionIdCorrect = async (institutionId) => {
    try {
      const docRef = doc(db, "eduTable", institutionId);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        console.log("Failed...", institutionId, docSnapshot.id);
        return false;
      }
      setInstitution(docSnapshot.data().name)

      return true;
    } catch (error) {
      console.error("Error checking document:", error);
      return false;
    }
  };

  const handleInstitutionIdEntered = async () => {

    const isInstitutionIdCorrect = await checkIfInstitutionIdCorrect(institutionId);
    if (!isInstitutionIdCorrect) {
      setInstitutionId("");
      return setInstitutionIdError(true);
    }

    // setInstitutionId("");
    setShowEnterInstitutionId(false);
    setInstitutionIdError(false);
    setSelectedButton(1);
    setCorrectInstitutionIdEntered(true);
  };

  // const handlePrivateClicked = () => {
  //     setShowEnterInstitutionId(!showEnterInstitutionId)
  //     setInstitutionIdError(false);
  //     setSelectedButton(0);
  //     setSelectedButton(selectedButton === 1 ? null : 1);
  // };

  const handleClose = () => {
    setInstitutionIdError(false);
  };

  const checkUserType = async (userId) => {

    if (userId != null) {
      try {
        const overviewQuery = query(
          collection(db, "eduTable"),
          where("name", "==", institution),
          where("admin", "==", userId)
        );

        const overviewSnapshot = await getDocs(overviewQuery);

        if (overviewSnapshot.empty) {
          setUserType("user");
        } else {
          setUserType("admin");
        }
      } catch (error) {
        console.error("Firebase error: ", error);
        setUserType('')
      }
    } else setUserType('')
  };

  function renderInstitution() {
    return (<Card
      elevation={5}
      sx={{
        width: "334px",
        height: '40px',
        marginTop: "1em",
        marginBottom: "1em",
        marginLeft: "6px",
        marginRight: "6px",
        fontSize: '16px',
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        color: '#af52bf',
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
      }}
    >
      {institution}
    </Card>
    )
  }
  function handlePolls() {
    setCorpPoll(true)
    setCorpQuiz(false)
    if (!(selectedButton === 1)) {
      setSelectedButton(1);
    } else {
      setSelectedButton(0);
    }
  }
  function handleQuizzes() {
    setCorpPoll(false)
    setCorpQuiz(true)
    if (!(selectedButton === 2)) {
      setSelectedButton(2);
    } else {
      setSelectedButton(0);
    }
  }
  return (
    <Box>
      <Card
        elevation={10}
        sx={{
          width: "334px",
          marginTop: "1em",
          marginBottom: ".25em",
          marginLeft: "8px",
          marginRight: "4px",
        }}
      >
        {showEnterInstitutionId ? (
          <div>

            <TextField
              label="Institution ID"
              variant="outlined"
              sx={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}
              value={institutionId}
              onChange={(e) => setInstitutionId(e.target.value)}
            />
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              style={{
                border: "none",
                width: "100px",
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: 28,
                color: "black",
                backgroundColor: "#D1D1D1",
              }}
              onClick={handleInstitutionIdEntered}
            >
              Save
            </Button>

          </div>
        ) : (null)
        }

        {showEnterInstitutionId && institutionIdError && (
          <Alert
            severity="error"
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          >
            <span style={{ fontStyle: "italic" }}>
              Enter the correct ID, or contact your adminstrator
            </span>
          </Alert>
        )}
      </Card>

      {correctInstitutionIdEntered && !userId && (
        <Login userId={userId} onLogin={onLogin} landingPage={'private'} />)}

      {correctInstitutionIdEntered && userId && (
        <>
          {renderInstitution()}
          {userType === 'admin' ?
            <ProCorpPrivateQuizAdmin userId={userId} db={db} institution={institution} /> :
            <ProCorpPrivateQuizUser userId={userId} db={db} institution={institution} />}
        </>
      )}
    </Box>
  );
}

export default ProEducation;
