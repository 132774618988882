import React, { useState, useEffect } from "react";
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Card,
  Alert,
  Box,
  Popover,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  IconButton,
  Button,
  FormControl,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';

import { collection, doc, getDoc, where, query, updateDoc, deleteDoc, getDocs, setDoc, addDoc } from "firebase/firestore"
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import { stateOptions } from "./MyData";
import { states } from "./MyData";
import { category } from "./MyData";
import { lawmakerKeyprops } from "./MyData";
import defaultPic from "./enzo.jpg";
import axios from "axios";
import { CriteriaDisplay } from "./Ratings";
//import { Chart } from 'react-google-charts';
import { fontWeight } from "@mui/system";

function Pro(props) {
  const { userId, db } = props;
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock with AM/PM notation
  };
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showDelDisallowAlert, setShowDelDisallowAlert] = useState(false);
  const [mouseRowChanged, setMouseRowChanged] = useState(false)
  // const [dialog1, setDialog1] = useState(false);
  // const [dialog2, setDialog2] = useState(false);
  const [likesUpdated, setLikesUpdated] = useState(false);
  const [opinion, setOpinion] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [approvalInSelectedRow, setApprovalInSelectedRow] = useState([0]);
  const [dbDataUpdated, setDbDataUpdated] = useState(false);

  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedLawmaker, setSelectedLawmaker] = React.useState(null);
  const [selectedCategory, setSelectedcategory] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowCount, setRowCount] = React.useState(0);
  const [showForm, setShowForm] = React.useState(false);
  const [likes, setLikes] = React.useState(0);
  const [sort, setSort] = useState({ column: "Count", order: "desc" });
  const [anchor, setAnchor] = useState(null);
  const [currentKeyprop, setCurrentKeyprop] = useState("Nothing here");
  const [currentPic, setCurrentPic] = useState(defaultPic);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [ratings, setRatings] = React.useState({
    Lawmaker: null,
    C1: 3,
    C2: 2,
    C3: 5,
    C4: 1,
    C5: 3,
  });
  const [ratingsData, setRatingsData] = useState([]);

  const handleRatingChange = (criteria) => (event, ratingValue) => {
    setRatings((prevRatings) => ({ ...prevRatings, [criteria]: ratingValue }));
  };
  const getRatings = () => {
    return (
      <Stack marginTop="10px" spacing={1} direction="row">
        <CriteriaDisplay
          criteria="Resolves Issues"
          value={ratings.C1}
          onChange={handleRatingChange("C1")}
        />
        <CriteriaDisplay
          criteria="Panders to Special Interests"
          value={ratings.C2}
          onChange={handleRatingChange("C2")}
        />

        <CriteriaDisplay
          criteria="Trustworthy"
          value={ratings.C3}
          onChange={handleRatingChange("C3")}
        />
        <CriteriaDisplay
          criteria="Conduct"
          value={ratings.C4}
          onChange={handleRatingChange("C4")}
        />
        <CriteriaDisplay
          criteria="Competence"
          value={ratings.C5}
          onChange={handleRatingChange("C5")}
        />
        {/* <CriteriaDisplay
          criteria="AnythingElse"
          value={ratings.C6}
          onChange={handleRatingChange('C6')}
        /> */}
      </Stack>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (inputIndex) => {
    const index = inputIndex + page * rowsPerPage;
    const newResults = [...results];
    const rowId = results[index].id;
    const lmaker = results[index].Lawmaker;

    if (results[index].UserId !== userId && userId !== "rpaplus") {
      // setDialog2(true);
      setShowDelDisallowAlert(true);
    } else {
      try {
        // console.log ("row:", rowId)
        // Delete the document with the specified ID
        await deleteDoc(doc(db, "lawmakerOverview", rowId));

        // Update the component's state
        newResults.splice(index, 1);
        setResults(newResults);
        const overviewQuery = query(
          collection(db, "lawmakerOverview"),
          where("Lawmaker", "==", lmaker)
        );
        const overviewSnapshot = await getDocs(overviewQuery);
        if (overviewSnapshot.empty) {
          const detailsQuery = query(
            collection(db, "lawmakerRatingDetails"),
            where("Lawmaker", "==", lmaker)
          );
          const detailsSnapshot = await getDocs(detailsQuery);

          detailsSnapshot.forEach(async (detailDoc) => {
            await deleteDoc(detailDoc.ref);
          });
        }
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  // const sortedResults = results.sort((a, b) => {
  //   if (sort.order === "asc") {
  //     if (sort.dataType === "string") {
  //       return a[sort.column].localeCompare(b[sort.column]);
  //     } else if (sort.dataType === "number") {
  //       // Convert values to numbers and compare
  //       return parseFloat(a[sort.column]) - parseFloat(b[sort.column]);
  //     } else {
  //       // Handle other data types if needed
  //       return 0;
  //     }
  //   } else {
  //     if (sort.dataType === "string") {
  //       return b[sort.column].localeCompare(a[sort.column]);
  //     } else if (sort.dataType === "number") {
  //       // Convert values to numbers and compare
  //       return parseFloat(b[sort.column]) - parseFloat(a[sort.column]);
  //     } else {
  //       // Handle other data types if needed
  //       return 0;
  //     }
  //   }
  // });

  const sortedResults = results.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.column] > b[sort.column] ? 1 : -1;
    } else {
      return b[sort.column] > a[sort.column] ? 1 : -1;
    }
  });

  const dataToDisplay = sortedResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const lawmakerOverviewRef = collection(db, "lawmakerOverview");
  const fetchData = async () => {
    try {
      const overviewSnapshot = await getDocs(lawmakerOverviewRef);
      const overviewData = overviewSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResults(overviewData);

      const detailsSnapshot = await getDocs(
        collection(db, "lawmakerRatingDetails")
      );
      const detailsData = detailsSnapshot.docs.map((doc) => ({
        id: doc.id,
        approval: doc.data()?.Approval,
        ...doc.data(),
      }));
      setRatingsData(detailsData);

      const approval = detailsData[0]?.approval;
      setApprovalInSelectedRow(approval);
      // updateResultsApproval();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateResultsApproval = () => {
    const updatedResults = results.map((row) => {
      const matchingRatingsData = ratingsData.find(
        (matchingRow) => matchingRow.Lawmaker === row.Lawmaker
      );
      return { ...row, Approval: matchingRatingsData.Approval };
    });

    setResults(updatedResults);
  };

  useEffect(() => {
    // setUserId(userId);
    fetchData();
    if (!userId && showForm) {
      setShowForm(false)
    }
  }, [
    userId,
    likes,
    sort,
    //showForm,
    mouseRowChanged,
    selectedRow,
    selectedLawmaker,
    dbDataUpdated,
    approvalInSelectedRow,
    opinion,
    likesUpdated,
    rowCount,
  ]);

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
      setDbDataUpdated(!dbDataUpdated); // toggle
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async function addOrUpdateDocument(collectionName, data, rowId) {
    try {
      let q;
      if (rowId) {
        q = query(collection(db, collectionName), where("id", "==", rowId));
      } else {
        q = query(
          collection(db, collectionName),
          where("Lawmaker", "==", data.Lawmaker)
        );
      }
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      } else {
        await addDoc(collection(db, collectionName), data);
        setDbDataUpdated(!dbDataUpdated); // toggle
      }
    } catch (error) {
      console.error("Error adding or updating document:", error);
    }
  }

  // function UpdateRatings() {

  //   const rowToUpdate = ratingsData.find(
  //     (row) => row.Lawmaker === selectedLawmaker
  //   );
  //   const updateRow = { ...rowToUpdate };
  //   updateRow.Lawmaker = selectedLawmaker;
  //   updateRow.RICumVoteCounts = (updateRow.RICumVoteCounts || 0) + 1;
  //   updateRow.RICumVotes = (updateRow.RICumVotes || 0) + ratings.C1;
  //   updateRow.RIRating = Math.ceil(
  //     updateRow.RICumVotes / updateRow.RICumVoteCounts
  //   );
  //   updateRow.PTSICumVoteCounts = (updateRow.PTSICumVoteCounts || 0) + 1;
  //   updateRow.PTSICumVotes = (updateRow.PTSICumVotes || 0) + ratings.C2;
  //   updateRow.PTSIRating = Math.ceil(
  //     updateRow.PTSICumVotes / updateRow.PTSICumVoteCounts
  //   );
  //   updateRow.TCumVoteCounts = (updateRow.TCumVoteCounts || 0) + 1;
  //   updateRow.TCumVotes = (updateRow.TCumVotes || 0) + ratings.C3;
  //   updateRow.TRating = Math.ceil(
  //     updateRow.TCumVotes / updateRow.TCumVoteCounts
  //   );
  //   updateRow.ConCumVoteCounts = (updateRow.ConCumVoteCounts || 0) + 1;
  //   updateRow.ConCumVotes = (updateRow.ConCumVotes || 0) + ratings.C4;
  //   updateRow.ConRating = Math.ceil(
  //     updateRow.ConCumVotes / updateRow.ConCumVoteCounts
  //   );
  //   updateRow.ComCumVoteCounts = (updateRow.ComCumVoteCounts || 0) + 1;
  //   updateRow.ComCumVotes = (updateRow.ComCumVotes || 0) + ratings.C5;
  //   updateRow.ComRating = Math.ceil(
  //     updateRow.ComCumVotes / updateRow.ComCumVoteCounts
  //   );
  //   updateRow.Approval = Math.ceil(
  //     (updateRow.RIRating +
  //       updateRow.PTSIRating +
  //       updateRow.TRating +
  //       updateRow.ConRating +
  //       updateRow.ComRating) /
  //     5
  //   );
  //   setApprovalInSelectedRow(updateRow.Approval);
  //   addOrUpdateDocument("lawmakerRatingDetails", updateRow, null);
  //   return (approvalInSelectedRow)
  // }

  //*/
  async function updateApprovals(lawmaker, approval) {
    const overviewQuery = query(
      collection(db, "lawmakerOverview"),
      where("Lawmaker", "==", lawmaker)
    );
    const overviewSnapshot = await getDocs(overviewQuery);
    if (!overviewSnapshot.empty) {

      for (const doc of overviewSnapshot.docs) {
        await updateDoc(doc.ref, { Approval: approval });
      }
      setDbDataUpdated(!dbDataUpdated); // toggle

    }
  }
  //*/
  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      selectedState === null ||
      selectedLawmaker === null ||
      selectedCategory === null
    ) {
      return (
        <Alert severity="info">
          Need To Select State, Lawmaker and Category, or Click ADD ENTRY again
          to go back
        </Alert>
      );
    }
    const rowToUpdate = ratingsData.find(
      (row) => row.Lawmaker === selectedLawmaker
    );
    const updateRow = { ...rowToUpdate };
    updateRow.Lawmaker = selectedLawmaker;
    updateRow.RICumVoteCounts = (updateRow.RICumVoteCounts || 0) + 1;
    updateRow.RICumVotes = (updateRow.RICumVotes || 0) + ratings.C1;
    updateRow.RIRating = Math.ceil(
      (updateRow.RICumVotes * 100) / 5 / updateRow.RICumVoteCounts
    );
    updateRow.PTSICumVoteCounts = (updateRow.PTSICumVoteCounts || 0) + 1;
    updateRow.PTSICumVotes = (updateRow.PTSICumVotes || 0) + ratings.C2;
    updateRow.PTSIRating = Math.ceil(
      (updateRow.PTSICumVotes * 100) / 5 / updateRow.PTSICumVoteCounts
    );
    updateRow.TCumVoteCounts = (updateRow.TCumVoteCounts || 0) + 1;
    updateRow.TCumVotes = (updateRow.TCumVotes || 0) + ratings.C3;
    updateRow.TRating = Math.ceil(
      (updateRow.TCumVotes * 100) / 5 / updateRow.TCumVoteCounts
    );
    updateRow.ConCumVoteCounts = (updateRow.ConCumVoteCounts || 0) + 1;
    updateRow.ConCumVotes = (updateRow.ConCumVotes || 0) + ratings.C4;
    updateRow.ConRating = Math.ceil(
      (updateRow.ConCumVotes * 100) / 5 / updateRow.ConCumVoteCounts
    );
    updateRow.ComCumVoteCounts = (updateRow.ComCumVoteCounts || 0) + 1;
    updateRow.ComCumVotes = (updateRow.ComCumVotes || 0) + ratings.C5;
    updateRow.ComRating = Math.ceil(
      (updateRow.ComCumVotes * 100) / 5 / updateRow.ComCumVoteCounts
    );
    updateRow.Approval = Math.ceil(
      (updateRow.RIRating +
        // updateRow.PTSIRating +
        updateRow.TRating +
        updateRow.ConRating +
        updateRow.ComRating) /
      4
    );
    // console.log (updateRow.RIRating,updateRow.PTSIRating, updateRow.TRating,updateRow.ConRating, updateRow.ComRating, updateRow.Approval )

    addOrUpdateDocument("lawmakerRatingDetails", updateRow, null);

    const lawmakerOverviewData = {
      UserId: userId,
      State: selectedState,
      Lawmaker: selectedLawmaker,
      Approval: updateRow.Approval,
      Category: selectedCategory,
      Chat: opinion,
      Count: likes,
      Date: new Date(),
    };
    addEntryToTable("lawmakerOverview", lawmakerOverviewData);

    updateApprovals(selectedLawmaker, updateRow.Approval);

    resetSort(event);
    fetchData();
    setShowForm(false);
    setSelectedState(null);
    setSelectedLawmaker(null);
    setSelectedcategory(null);
    setOpinion("");
    setDbDataUpdated(!dbDataUpdated); // toggle;
  };

  // const handleSort = (column, dataType) => {
  //   if (sort.column === column && sort.dataType === dataType) {
  //     //remove dataType...
  //     setSort(prevSort => ({
  //       ...prevSort,
  //       order: prevSort.order === "asc" ? "desc" : "asc"
  //     }));
  //   } else {
  //     setSort({
  //       column: column,
  //       order: "asc"
  //     });
  //   }
  // };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ column: column, order: "desc" });
    }
  };

  // const handleSort = (column) => {
  //   if (sort.column === column) {
  //     setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
  //   } else {
  //     setSort({ column: column, order: "asc" });
  //   }
  // };

  const PopoverHandler = (event, result) => {
    if (popoverOpen) {
      setPopoverOpen(false);
      setAnchor(null);
      setCurrentKeyprop("Nothing Here");
      setCurrentPic({ defaultPic });
    } else {
      setAnchor(event.currentTarget);
      let dataResult = lawmakerKeyprops.find(
        (obj) => obj.lawmaker === result.Lawmaker
      );
      setCurrentKeyprop(dataResult.keyprop);
      setCurrentPic(dataResult.pic);
      setPopoverOpen(true);
    }
  };

  function resetSort(event) {
    setSort({ column: "Count", order: "desc" });
  }

  function handleStateChange(event, newValue) {
    setSelectedState(newValue);
    setSelectedLawmaker(null);
  }

  function handlecategoryChange(event, newValue) {
    setSelectedcategory(newValue);
  }

  async function incrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].Count += 1;
    setLikes(newResults[index].Count);

    // const newCount = newResults[index].Count +1
    // setRowCount(prevCount => {
    //   const newCount = prevCount + 1;
    //   setLikes(newCount);
    //   newResults[index].Count = newCount;
    //   return newCount;
    // });
    setResults(newResults);
    setLikesUpdated(!likesUpdated); //toggle

    const docRef = doc(db, "lawmakerOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { Count: newResults[index].Count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  async function decrementCount(inputIndex) {
    const newResults = [...results];
    const index = inputIndex + page * rowsPerPage;
    newResults[index].Count -= 1;
    setLikes(newResults[index].Count);
    // setRowCount(prevCount => {
    //   const newCount = prevCount - 1;
    //   setLikes(newCount);
    //   newResults[index].Count = newCount;
    //   return newCount;
    // });
    setResults(newResults);

    setLikesUpdated(!likesUpdated); //toggle
    const docRef = doc(db, "lawmakerOverview", newResults[index].id);
    // const rowId = newResults[index].id;
    try {
      await updateDoc(docRef, { Count: newResults[index].Count });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  const ratingCat = [
    // "Approval",
    "RIRating",
    "PTSIRating",
    "TRating",
    "ConRating",
    "ComRating",
  ];
  const ratingsLegend = [
    // "Approval",
    "Resolves Issues",
    "Panders to Special Interests",
    "Trustworthy",
    "Conduct",
    "Competence",
  ];

  const handleMouseEnter = (index) => {
    const currentPageIndex = page * rowsPerPage;
    const rowIndex = currentPageIndex + index;
    const rowData = sortedResults[rowIndex];
    setMouseRowChanged(!mouseRowChanged) //toggle
    setSelectedRow(rowData);
  };

  // const handleMouseEnter = (index) => {
  //   const rowData = results[index];
  //   setSelectedRow(rowData);
  // };

  // const handleMouseEnter = (index) => {
  //   const rowData = results[index];
  //   const lRatings = ratingsData.find(
  //     (matchingRow) => matchingRow.Lawmaker === rowData.Lawmaker
  //   );

  //   setSelectedRow(lRatings);

  //   // setShowCard(true);
  // };

  const handleMouseLeave = () => {
    setSelectedRow(null);
    //   setShowCard(false);
  };

  function returnFormData() {
    return (
      <Grid item xs={12}>
        <Card
          elevation={10}
          style={{
            align: "left",
            justifyContent: "flex-start",
            marginBottom: "1em",
            marginLeft: "6px",

            width: "1300px",
          }}
        >
          <form justifyContent="flex-start" onSubmit={handleSubmit}>
            <Stack marginTop="10px" spacing={1} direction="row">
              <Autocomplete
                value={selectedState}
                variant="filled"
                style={{ marginLeft: "6px", width: 250 }}
                color="default"
                options={states}
                onChange={handleStateChange}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="State" variant="filled" />
                )}
              />
              <Autocomplete
                value={selectedLawmaker}
                variant="filled"
                style={{ width: 250 }}
                color="default"
                options={stateOptions
                  .filter((stateOption) => stateOption.name === selectedState)
                  .map((stateOption) => stateOption.lawmaker)
                  .flat()}
                onChange={(event, newValue) => setSelectedLawmaker(newValue)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="Lawmaker" variant="filled" />
                )}
              />
            </Stack>
            {selectedLawmaker && (
              <Box>
                <Stack
                  marginTop="8px"
                  marginBottom="1px"
                  marginLeft="3px"
                  spacing={1}
                >
                  {getRatings()}
                  <Autocomplete
                    value={selectedCategory}
                    variant="filled"
                    style={{ marginLeft: "3px", marginTop: "1em", width: 250 }}
                    color="default"
                    options={category}
                    onChange={handlecategoryChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Chat, Category"
                        variant="filled"
                      />
                    )}
                  ></Autocomplete>
                  {selectedCategory && (
                    <FormControl marginLeft="3px">
                      <Stack marginTop="8px" marginLeft="0px" spacing={3}>
                        <TextField
                          label="Chat:"
                          style={{ marginLeft: "3px", width: "768px" }}
                          textAlign="justify"
                          inputProps={{ maxlength: 300, wrap: "hard" }}
                          variant="outlined"
                          multiline="true"
                          value={opinion}
                          onChange={(event) => setOpinion(event.target.value)}
                          helperText={`${opinion.length}/300`}
                        />
                      </Stack>
                    </FormControl>
                  )}
                </Stack>
              </Box>
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 80,
                margin: "0 0 0 4px",
                backgroundColor: "#5B76D7",
                color: "white",
                marginLeft: "3px",
              }}
              type="submit"
            >
              Submit
            </Button>
            <div style={{ height: "1em" }}></div>
          </form>
        </Card>
      </Grid>
    );
  }

  const handleRateChatClick = () => {
    if (userId) {
      setShowForm(!showForm);
    } else {
      setShowLoginAlert(true);
      // console.log("No UserId...");
      // setDialog1(true);
    }
  };

  const MyDialog = ({ message, onClose }) => {
    return (
      <>

        <Snackbar
          open
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            marginTop: "100px"
          }}
        >
          <Alert onClose={handleClose} severity="error">
            <span style={{ fontSize: 16, fontStyle: 'italic' }}>Only the originator can delete...</span>
          </Alert>
        </Snackbar>
      </>
    );
    //Delete the Dialog stuff below if you find a solution..
    {/* <Dialog
        open
        onClose={onClose}
        sx={{ backgroundColor: "rgba(255, 255, 255)" }}
      >
        <DialogTitle>Alert: </DialogTitle>
        <DialogContent>
          <Paper sx={{ minWidth: "200px", backgroundColor: "#f5f5f5" }}>
            {message}
           </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open /> */}
  };

  const handleClose = () => {
    if (showLoginAlert) setShowLoginAlert(false);
    if (showDelDisallowAlert) setShowDelDisallowAlert(false);
    // if (dialog1) setDialog1(false);
    // if (dialog2) setDialog2(false);
  };

  function getMatchingLawmakerData(Lawmaker) {
    const matchingRatingData = ratingsData.find(
      (data) => data.Lawmaker === Lawmaker
    );
    if (matchingRatingData) {
      return matchingRatingData;
    }
    return null;
  }
  const matchingRatingData = selectedRow
    ? getMatchingLawmakerData(selectedRow.Lawmaker)
    : null;

  return (
    <>
      <Box marginTop={2}>
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <Stack
              spacing={3}
              maxWidth="860px"
              direction="row"
              marginTop={2}
              marginBottom={3}
            >
              <Button
                variant="contained"
                backgroundcolor="#333"
                // endIcon={<SendIcon backgroundColor="grey" />}
                onClick={handleRateChatClick}
                style={{
                  border: "none",
                  width: "130px",
                  marginLeft: "8px",
                  borderRadius: 28,
                  color: "black",
                  backgroundColor: "#D1D1D1",
                }}
              >
                RATE, CHAT
              </Button>
              <br />
            </Stack>
            {showLoginAlert && (
              <Snackbar
                open
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{
                  marginTop: "100px"
                }}
              >
                <Alert onClose={handleClose} severity="error">
                  <span style={{ fontSize: 16, fontStyle: 'italic' }}>Login first...</span>
                </Alert>
              </Snackbar>
            )}
          </Grid>
          {showForm && returnFormData()}

          <Grid container spacing={9} direction="row">
            <Grid item xs={6}>
              <Card
                elevation={10}
                sx={{
                  width: "1350px",
                  marginLeft: "15px",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ overflow: "auto", marginTop: "0.5em" }}
                >
                  <Table
                    size="small"
                    sx={{ width: "90%", tableLayout: "fixed" }}
                  >
                    <TableHead
                      sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                    >
                      <TableRow dense>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "25%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          UserId
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("UserId")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("UserId")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "30%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          State
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("State")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("State")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "break-word",
                            width: "40%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Click for info & to exit">
                            <span>
                              Lawmaker
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Lawmaker")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Lawmaker")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "break-word",
                            width: "38%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Approval
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Approval")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Approval")}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "50%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Chat Category
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Category")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Chat Category")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "100%",
                            wordWrap: "break-word",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          <Tooltip title="Sort by Likes">
                            <span>
                              Chat
                              {sort.order === "asc" ? (
                                <ArrowDropUp
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Count")}
                                />
                              ) : (
                                <ArrowDropDown
                                  sx={{ color: "white" }}
                                  onClick={() => handleSort("Count")}
                                />
                              )}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "50%",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Date
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Date")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("Date")}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell >Count</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}
                    >
                      {dataToDisplay.map((result, index) => (
                        <TableRow
                          hover
                          key={index}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <TableCell>{result.UserId}</TableCell>
                          <TableCell>{result.State}</TableCell>
                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "nowrap" }}
                            onClick={(event) => PopoverHandler(event, result)}
                          >
                            {result.Lawmaker}
                            <Popover
                              open={Boolean(anchor)}
                              anchorEl={anchor}
                              onClose={() => PopoverHandler(null, null)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <Card>
                                <Typography
                                  align="justify"
                                  sx={{
                                    backgroundColor: "#F7F9F9",
                                    fontSize: 11,
                                    color: "#7286D3",
                                    wordWrap: "break-word",
                                    marginTop: 1,
                                    width: "300px",
                                    paddingRight: "5px",
                                    overflowY: "scroll",
                                    height: 300,
                                  }}
                                >
                                  ,
                                  <img
                                    src={currentPic}
                                    width="35%"
                                    height="35%"
                                  />
                                  <br></br>
                                  {currentKeyprop}
                                </Typography>
                              </Card>
                            </Popover>
                          </TableCell>
                          <TableCell align="center">
                            {result.Approval}%
                          </TableCell>
                          <TableCell
                            align="justify"
                            sx={{ whiteSpace: "break-word" }}
                          >
                            {result.Category}
                          </TableCell>
                          <TableCell
                            align="justify"
                            sx={{ wordWrap: "break-word" }}
                          >
                            {result.Chat}
                          </TableCell>

                          <TableCell
                            align="justify"
                            sx={{ wordWrap: "nowrap" }}
                          >
                            {result.Date.toDate().toLocaleString(
                              "en-US",
                              dateOptions
                            )}
                          </TableCell>
                          {/* <TableCell>{result.Count}</TableCell> */}

                          <TableCell>
                            <Stack spacing={0.25} direction="row" align="top">
                              <IconButton onClick={() => incrementCount(index)}>
                                <ThumbUp />
                              </IconButton>
                              <IconButton onClick={() => decrementCount(index)}>
                                <ThumbDown />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                            {showDelDisallowAlert && (
                              <MyDialog
                                message="Only the originator can delete..."
                                onClose={handleClose}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={sortedResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            </Grid>
            {selectedRow && (
              <Grid item xs={6}>
                <Card
                  sx={{
                    maxHeight: "450px",
                    // height: "90vh",
                    marginLeft: "520px",
                    width: "250px",
                    overflow: "auto",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            align="center"
                            style={{
                              height: "10px",
                              overflow: "hidden",
                              backgroundColor: "#5B76D7",
                            }}
                          >
                            <Typography color="white">
                              <h3>{selectedRow.Lawmaker}</h3>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ratingCat.map((cat, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="tr"
                              scope="row"
                              style={{ color: "#5B76D7" }}
                            >
                              {ratingsLegend[index]}
                            </TableCell>
                            <TableCell sx={{ backgroundColor: "#F8F8F8" }}>
                              {matchingRatingData
                                ? matchingRatingData[cat]
                                : null}
                              %
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* {!userId && (
          <Alert severity="info" action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }>
            <span style={{ fontStyle: 'italic' }}>Login to Rate CX, Chat...</span>
          </Alert>
        )} */}
      </Box>
    </>
  );
}

export default Pro;
