import React, { useState, useEffect, } from 'react'
import {
    Card,
    Box,
    Typography,
    TextField,
    Grid,
    Stack,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Select,
    CardContent,
} from "@mui/material";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Rating from "@mui/material/Rating";
import { collection, doc, where, query, updateDoc, deleteDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"

import { CriteriaDisplay, RatingDisplay } from "./Ratings";

function DBOpenQuizResultsPrivate(props) {

    const { userId, db, institution } = props;
    const [myScore, setMyScore] = useState(0);
    const [adminLoggedIn, setAdminLoggedIn] = useState(false);

    const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true, // Use 12-hour clock with AM/PM notation
    };
    const [sort, setSort] = useState({ column: "count", order: "desc" });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    const [starRating, setStarRating] = React.useState(0);
    const [yesRating, setYesRating] = React.useState(0);
    const [noRating, setnoRating] = React.useState(0);
    const [agreeRating, setAgreeRating] = React.useState(0);
    const [disagreeRating, setDisagreeRating] = React.useState(0);
    const [excellentRating, setExcellentRating] = React.useState(0);
    const [goodRating, setGoodRating] = React.useState(0);
    const [averageRating, setAverageRating] = React.useState(0);
    const [badRating, setBadRating] = React.useState(0);
    const [terribleRating, setTerribleRating] = React.useState(0);
    const [showSelectForm, setShowSelectForm] = React.useState(false);
    const [numVotes, setNumVotes] = React.useState(0);
    const [averageStarRating, setAverageStarRating] = React.useState(0);
    const [quizResults, setQuizResults] = useState([]);
    const [quizName, setQuizName] = useState('');


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (column) => {
        if (sort.column === column) {
            setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
        } else {
            setSort({ column: column, order: "asc" });
        }
    };

    function updateUserResponses(questions, userResponses) {
        userResponses.map((usr, indx1) => (
            usr.answers.map((answer, indx2) => {
                let temp = `${answer} ${answer === questions[indx2].correctAnswer ? "✔️" : "❌"}`;
                userResponses[indx1].answers[indx2] = temp
            })
        ))
    }

    const transformData = (data) => {
        const transformedData = {};

        data.forEach((row) => {
            const { quizTitle, questions, userResponses, adminId, expiryDate, name, status, type, min, max, median, mean } = row;
            if (adminId === userId) {
                setAdminLoggedIn(true)
            }

            if (!transformedData[quizTitle]) {
                transformedData[quizTitle] = [];
            }
            setQuizName(quizTitle);

            userResponses.map((resp) => {
                if (resp.userId === userId)
                    setMyScore(resp.score)
            })
            updateUserResponses(questions, userResponses)

            transformedData[quizTitle].push({
                adminId, questions, userResponses, expiryDate, name, status, type, min, max, median, mean
            });
        });

        return transformedData;
    };
    const fetchData = async () => {

        try {
            const activeTableQuery = query(collection(db, 'quizTable'),
                where('name', '==', institution),
                where('status', '==', 'open')
            );
            const activeTableSnapshot = await getDocs(activeTableQuery);

            const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setQuizResults(transformData(activeTableData))


        } catch (error) {
            if (userId === '') {
                alert("Login first")
            } else {
                console.log("ERROR!")
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        fetchData();

    }, [averageStarRating, selectedRow, userId, institution, showSelectForm, numVotes, yesRating, noRating, excellentRating, goodRating, badRating, averageRating, terribleRating]);

    function renderQuizUser() {
        return (
            <Box marginTop={2} >
                <Grid container spacing={1} direction='row'>
                    <Grid item xs={12}  >
                        <Card
                            elevation={10}
                            sx={{ width: "1350px" }}
                        >
                            <TableContainer
                                component={Paper}
                                sx={{
                                    overflow: "auto", marginRight: '10px', marginTop: "0.5em", display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Table size="small" sx={{ width: "99%" }}>
                                    <TableHead
                                        sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                                    >
                                        <TableRow >
                                            <TableCell
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    width: "20%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Quiz Title
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    width: "15%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Due Date
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("expiryDate")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("expiryDate")}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-all",
                                                    width: "10%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Admin
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}
                                            </TableCell>

                                            <TableCell
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-all",
                                                    width: "35%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Question
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-all",
                                                    width: "20%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                    paddingRight: '10px'
                                                }}
                                            >
                                                Scores
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {Object.entries(quizResults).map(([quizTitle, users]) => (
                                            Object.entries(users).map(([uId, info]) => (
                                                <>
                                                    <TableRow key={`${quizTitle}-${uId}`}>
                                                        <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }} > {quizTitle} </TableCell>
                                                        <TableCell sx={{ verticalAlign: 'top' }}>{info.expiryDate.toDate().toLocaleString(
                                                            "en-US",
                                                            dateOptions
                                                        )}</TableCell>
                                                        <TableCell sx={{ verticalAlign: 'top' }}>{info.adminId}</TableCell>
                                                        <TableCell align="justify" sx={{
                                                            verticalAlign: 'top',
                                                        }} rowSpan={info.length}>
                                                            {info.questions.map((q, index) => (
                                                                q.question ?
                                                                    <>
                                                                        {q.question} <br />
                                                                    </>
                                                                    : null
                                                            ))}
                                                        </TableCell>
                                                        <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }}>
                                                            <>
                                                                <div style={{ display: 'grid', gridTemplateColumns: '80px auto', gap: '1px' }}>

                                                                    {(info.adminId === userId) ? null :
                                                                        <>
                                                                            <Typography sx={{ color: 'blue', fontWeight: 'bold', fontSize: '14px' }}>You:</Typography>
                                                                            <Typography sx={{ color: 'blue', fontWeight: 'bold', fontSize: '14px' }}>{myScore}%</Typography>
                                                                        </>}
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>Minimum:</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.min}%</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}> Maximum:</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.max}%</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>Median:</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.median}%</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>Average:</Typography>
                                                                    <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.mean}%</Typography>
                                                                </div>
                                                            </>
                                                        </TableCell>

                                                    </TableRow>
                                                </>
                                            ))
                                        ))}
                                    </TableBody>
                                </Table>
                                {/* <TablePagination
                        component="div"
                        count={sortedResults.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                            </TableContainer>
                        </Card>
                    </Grid>
                </Grid>

            </Box >
        )
    }
    function renderQuizAdmin() {
        return (
            <>
                <Box marginTop={2} >
                    <Grid container spacing={1} direction='row'>
                        <Grid item xs={12}  >
                            <Card
                                elevation={10}
                                sx={{ width: "1350px" }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        overflow: "auto", marginRight: '10px', marginTop: "0.5em", display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Table size="small" sx={{ width: "99%" }}>
                                        <TableHead
                                            sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                                        >
                                            <TableRow >
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        width: "20%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                    }}
                                                >
                                                    Quiz Title
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        width: "15%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                    }}
                                                >
                                                    Due Date
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("expiryDate")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("expiryDate")}
                                                        />
                                                    )}
                                                </TableCell>
                                                {/* <TableCell
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-all",
                                                    width: "10%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Admin
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}
                                            </TableCell> */}

                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-all",
                                                        width: "35%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                    }}
                                                >
                                                    Question
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-all",
                                                        width: "20%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        paddingRight: '10px'
                                                    }}
                                                >
                                                    Scores
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {Object.entries(quizResults).map(([quizTitle, users]) => (
                                                Object.entries(users).map(([uId, info]) => (
                                                    <>
                                                        <TableRow key={`${quizTitle}-${uId}`}>
                                                            <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }} > {quizTitle} </TableCell>
                                                            <TableCell sx={{ verticalAlign: 'top' }}>{info.expiryDate.toDate().toLocaleString(
                                                                "en-US",
                                                                dateOptions
                                                            )}</TableCell>
                                                            {/* <TableCell sx={{ verticalAlign: 'top' }}>{info.adminId}</TableCell> */}
                                                            <TableCell align="justify" sx={{
                                                                verticalAlign: 'top',
                                                            }} rowSpan={info.length}>
                                                                {info.questions.map((q, index) => (
                                                                    q.question ?
                                                                        <>
                                                                            {q.question} <br />
                                                                        </>
                                                                        : null
                                                                ))}
                                                            </TableCell>
                                                            <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }}>
                                                                <>
                                                                    <div style={{ display: 'grid', gridTemplateColumns: '80px auto', gap: '1px' }}>

                                                                        {(info.adminId === userId) ? null :
                                                                            <>
                                                                                <Typography sx={{ color: 'blue', fontWeight: 'bold', fontSize: '14px' }}>You:</Typography>
                                                                                <Typography sx={{ color: 'blue', fontWeight: 'bold', fontSize: '14px' }}>{myScore}%</Typography>
                                                                            </>}
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>Minimum:</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.min}%</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}> Maximum:</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.max}%</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>Median:</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.median}%</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>Average:</Typography>
                                                                        <Typography sx={{ color: '#333333', fontSize: '14px' }}>{info.mean}%</Typography>
                                                                    </div>
                                                                </>
                                                            </TableCell>

                                                        </TableRow>
                                                    </>
                                                ))
                                            ))}
                                        </TableBody>
                                    </Table>

                                    {/* <TablePagination
                            component="div"
                            count={sortedResults.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>

                </Box >
                <Box marginTop={2} >
                    <Grid container spacing={1} direction='row'>
                        <Grid item xs={12}  >
                            <Card
                                elevation={10}
                                sx={{ width: "1350px" }}
                            >
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        overflow: "auto", marginRight: '10px', marginTop: "0.5em", display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Table size="small" sx={{ width: "99%" }}>
                                        <TableHead
                                            sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                                        >
                                            <TableRow >
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-all",
                                                        width: "20%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        paddingRight: '10px'
                                                    }}
                                                >
                                                    User
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-all",
                                                        width: "20%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        paddingRight: '10px'
                                                    }}
                                                >
                                                    Score
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        wordBreak: "break-all",
                                                        width: "60%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        paddingRight: '10px'
                                                    }}
                                                >
                                                    Answers
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("question")}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {Object.entries(quizResults).map(([quizTitle, users]) => (
                                                Object.entries(users).map(([uId, info]) => (
                                                    <>
                                                        {info.userResponses.map((u, index) => (
                                                            <TableRow key={`${quizTitle}-${uId}`}>
                                                                {/*    <TableCell align="justify" sx={{
                                                                verticalAlign: 'top',
                                                           }} rowSpan={info.length}> */}
                                                                <TableCell>{u.userId}</TableCell>
                                                                <TableCell sx={{ verticalAlign: 'top', marginTop: "1em", backgroundColor: "#F7F9F9" }}>
                                                                    <Typography sx={{ color: 'blue', fontWeight: 'bold', fontSize: '14px' }}>{u.score}%</Typography>
                                                                </TableCell>
                                                                <TableCell>{u.answers}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ))
                                            ))}
                                        </TableBody>
                                    </Table>

                                    {/* <TablePagination
                        component="div"
                        count={sortedResults.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>

                </Box >
            </>
        )
    }

    return (
        adminLoggedIn ? renderQuizAdmin() : (
            renderQuizUser()
        )
    )
}

export default DBOpenQuizResultsPrivate