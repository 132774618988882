import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  FormControl,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Rating from "@mui/material/Rating";
import CircularProgress from '@mui/material/CircularProgress';
//import axios from "axios";
import { Timestamp, collection, doc, where, query, arrayUnion, updateDoc, deleteDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"
import { QuestionMark } from "@mui/icons-material";
import { getValue } from "firebase/remote-config";

function PollInvitesPrivate(props) {

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isPollRateComplete, setIsPollRateComplete] = useState(false);
  const { userId, company, db } = props;
  const [critNumber, setCritNumber] = useState(0);
  const [voteCompleteId, setVoteCompleteId] = useState(0);

  const [currentRatingName, setCurrentRatingName] = useState('');

  const [selectedQuestion, setSelectedQuestion] = React.useState('');
  const [selectedRating, setSelectedRating] = React.useState('');

  const [pollResults, setPollResults] = useState([]);
  const [ratingResults, setRatingResults] = React.useState([]);
  const [sort, setSort] = useState({ column: "count", order: "desc" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  // const [popoverOpen, setPopoverOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const [starRating, setStarRating] = React.useState(0);
  const [yesRating, setYesRating] = React.useState(0);
  const [noRating, setNoRating] = React.useState(0);
  const [agreeRating, setAgreeRating] = React.useState(0);
  const [disagreeRating, setDisagreeRating] = React.useState(0);
  const [excellentRating, setExcellentRating] = React.useState(0);
  const [goodRating, setGoodRating] = React.useState(0);
  const [averageRating, setAverageRating] = React.useState(0);
  const [badRating, setBadRating] = React.useState(0);
  const [terribleRating, setTerribleRating] = React.useState(0);
  const [showSelectForm, setShowSelectForm] = React.useState(false);
  // const [numVotes, setNumVotes] = React.useState(0);
  const [avgStarRating, setAvgStarRating] = React.useState(0);
  let uniqueValues = new Set();
  // const [ratTableData, setRatTableData] = useState([initializeRatTableRow()]);
  // const ratTableStartIndex = 0;
  // const ratTableEndIndex = ratTableData.length - 1;

  function initializeRatTableRow() {
    let [
      id, userId, question, tarName, crit1, crit1RO, crit1ROV1, crit1ROV2, crit1ROV3, crit1ROV4, crit1ROV5, crit1ROStarVal, crit1NumVotes,
      crit2, crit2RO, crit2ROV1, crit2ROV2, crit2ROV3, crit2ROV4, crit2ROV5, crit2ROStarVal, crit2NumVotes,
      crit3, crit3RO, crit3ROV1, crit3ROV2, crit3ROV3, crit3ROV4, crit3ROV5, crit3ROStarVal, crit3NumVotes,
      crit4, crit4RO, crit4ROV1, crit4ROV2, crit4ROV3, crit4ROV4, crit4ROV5, crit4ROStarVal, crit4NumVotes,
      crit5, crit5RO, crit5ROV1, crit5ROV2, crit5ROV3, crit5ROV4, crit5ROV5, crit5ROStarVal, crit5NumVotes,
      ratingStatus, expiryDate
    ] = Array(39).fill(null);

    return [
      id, userId, question, tarName, crit1, crit1RO, crit1ROV1, crit1ROV2, crit1ROV3, crit1ROV4, crit1ROV5, crit1ROStarVal, crit1NumVotes,
      crit2, crit2RO, crit2ROV1, crit2ROV2, crit2ROV3, crit2ROV4, crit2ROV5, crit2ROStarVal, crit2NumVotes,
      crit3, crit3RO, crit3ROV1, crit3ROV2, crit3ROV3, crit3ROV4, crit3ROV5, crit3ROStarVal, crit3NumVotes,
      crit4, crit4RO, crit4ROV1, crit4ROV2, crit4ROV3, crit4ROV4, crit4ROV5, crit4ROStarVal, crit4NumVotes,
      crit5, crit5RO, crit5ROV1, crit5ROV2, crit5ROV3, crit5ROV4, crit5ROV5, crit5ROStarVal, crit5NumVotes,
      ratingStatus, expiryDate
    ];
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleVoteComplete = async (id) => {
    if (userId === 'rpaplus') {
      setVoteCompleteId(id);
      setShowSelectForm(false)
      setLoading(false)
      setIsDisabled(false);
      return
    }

    const q = query(collection(db, 'pendingVotesTable'), where('pendingVotesTableId', '==', id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, 'pendingVotesTable', document.id);
      await updateDoc(docRef, {
        votesCompleted: arrayUnion(userId)
      });
    });
    setVoteCompleteId(id);
    setShowSelectForm(false)
    setLoading(false)
  };

  const handleStarRatingChange = async (value, type, critName, tarName, critNumber) => {
    let selectedData;

    setLoading(true)
    if (type === 'rating') {
      const ratingDocRef = doc(db, 'ratingTable', selectedRow.id);
      const ratingDocSnapshot = await getDoc(ratingDocRef);
      if (ratingDocSnapshot.exists()) {
        const ratingData = ratingDocSnapshot.data();
        selectedData = ratingData;
      } else {
        console.log("Document does not exist!");
        return
      }

      let critAvgStarVal = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROStarVal`]) || 0;
      let critNumVotes = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`]) || 0;

      const val1 = critAvgStarVal * critNumVotes;
      const val2 = val1 + parseInt(value);
      let uNumVotes = critNumVotes + 1
      let uAverageStarRating = Math.round(val2 / uNumVotes)
      setAvgStarRating(uAverageStarRating);

      const docRef = doc(db, 'ratingTable', selectedRow.id);
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`] = uNumVotes;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}RO`] = '5Stars';
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROStarVal`] = uAverageStarRating;
      await setDoc(docRef, selectedData, { merge: true });
    } else {

      const pollDocRef = doc(db, 'pollTable', selectedRow.id);
      const pollDocSnapshot = await getDoc(pollDocRef);

      if (pollDocSnapshot.exists()) {
        selectedData = pollDocSnapshot.data();
      } else {
        console.log("Document does not exist!");
      }

      const { averageStarRating, numVotes } = selectedData;

      const val1 = averageStarRating * numVotes;
      const val2 = parseInt(val1) + parseInt(value);
      let uNumVotes = numVotes + 1
      let uAverageStarRating = Math.round(val2 / uNumVotes)
      setAvgStarRating(uAverageStarRating);

      const docRef = doc(db, 'pollTable', selectedRow.id);
      const newData = { averageStarRating: uAverageStarRating, numVotes: uNumVotes, evalType: '5Stars', company: company };
      await setDoc(docRef, newData, { merge: true });

    }
    handleVoteComplete(selectedRow.id);
  }
  const handleAgreeDisagreeRatingChange = async (event, type, critName, tarName, critNumber) => {
    const newVote = event.target.value;

    setLoading(true)
    let selectedData;
    let updatedAgreeRating;
    let updatedDisagreeRating;

    if (type === 'rating') {
      const ratingDocRef = doc(db, 'ratingTable', selectedRow.id);
      const ratingDocSnapshot = await getDoc(ratingDocRef);
      if (ratingDocSnapshot.exists()) {
        const ratingData = ratingDocSnapshot.data();
        selectedData = ratingData;
      } else {
        console.log("Document does not exist!");
      }
      let critAgreeVotes = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`]) || 0;
      let critNumVotes = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`]) || 0;

      const val1 = critAgreeVotes / 100;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}`] = critName;

      let agreeRatingVotes = Math.round(val1 * critNumVotes);
      let disagreeRatingVotes = critNumVotes - agreeRatingVotes;

      if (newVote === '😃Agree') {
        agreeRatingVotes++;
      } else {
        disagreeRatingVotes++;
      }
      critNumVotes++;

      updatedAgreeRating = Math.round(agreeRatingVotes / critNumVotes * 100);
      updatedDisagreeRating = Math.round(disagreeRatingVotes / critNumVotes * 100)
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`] = critNumVotes;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}RO`] = 'agreeDisagree';
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`] = updatedAgreeRating;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV2`] = updatedDisagreeRating;
      const docRef = doc(db, 'ratingTable', selectedRow.id);
      await setDoc(docRef, selectedData, { merge: true });

    } else {

      const pollDocRef = doc(db, 'pollTable', selectedRow.id);
      const pollDocSnapshot = await getDoc(pollDocRef);

      if (pollDocSnapshot.exists()) {
        selectedData = pollDocSnapshot.data();
      } else {
        console.log("Document does not exist!");
      }

      const { agreeRating, numVotes } = selectedData;
      let agreeRatingVotes = Math.round((agreeRating / 100) * numVotes);
      let disagreeRatingVotes = numVotes - agreeRatingVotes;
      let updatedNumVotes = numVotes;

      if (newVote === 'Agree') {
        agreeRatingVotes++;
      } else {
        disagreeRatingVotes++;
      }
      updatedNumVotes++;
      updatedAgreeRating = Math.round(agreeRatingVotes / updatedNumVotes * 100);
      updatedDisagreeRating = Math.round(disagreeRatingVotes / updatedNumVotes * 100);

      setAgreeRating(updatedAgreeRating);
      setDisagreeRating(updatedDisagreeRating);

      const docRef = doc(db, 'pollTable', selectedRow.id);
      const newData = { agreeRating: updatedAgreeRating, disagreeRating: updatedDisagreeRating, numVotes: updatedNumVotes, evalType: 'agreeDisagree', company: company };

      await setDoc(docRef, newData, { merge: true });
    }
    handleVoteComplete(selectedRow.id);
  }
  const handleResponsivenessRatingChange = (event) => {

  }
  const handleQualityRatingChange = async (event, type, critName, tarName, critNumber) => {
    const value = event.target.value;
    let selectedRatingData;
    setLoading(true)
    if (type !== 'rating') {
      const { question, excellentRating, goodRating, averageRating, badRating, terribleRating, numVotes, id } = selectedRow; //Rating in %
      let updatedExcellentRating = excellentRating;
      let updatedGoodRating = goodRating;
      let updatedAverageRating = averageRating;
      let updatedBadRating = badRating;
      let updatedTerribleRating = terribleRating;

      let updatedExcellentVotes = excellentRating / 100 * numVotes;
      let updatedGoodVotes = goodRating / 100 * numVotes;
      let updatedAverageVotes = averageRating / 100 * numVotes;
      let updatedBadVotes = badRating / 100 * numVotes;
      let updatedTerribleVotes = terribleRating / 100 * numVotes;
      let updatedNumVotes = numVotes;

      switch (value) {
        case 'Excellent':
          updatedExcellentVotes = updatedExcellentVotes + 1;
          break;
        case 'Good':
          updatedGoodVotes = updatedGoodVotes + 1;
          break;
        case 'Average':
          updatedAverageVotes = updatedAverageVotes + 1;
          break;
        case 'Bad':
          updatedBadVotes = updatedBadVotes + 1;
          break;
        case 'Terrible':
          updatedTerribleVotes = updatedTerribleVotes + 1;
          break;
        default: console.log("SWERR!");
      }

      updatedNumVotes++;
      updatedExcellentRating = Math.round(updatedExcellentVotes * 100 / updatedNumVotes);
      updatedGoodRating = Math.round(updatedGoodVotes * 100 / updatedNumVotes);
      updatedAverageRating = Math.round(updatedAverageVotes * 100 / updatedNumVotes);
      updatedBadRating = Math.round(updatedBadVotes * 100 / updatedNumVotes);
      updatedTerribleRating = Math.round(updatedTerribleVotes * 100 / updatedNumVotes);

      //const sumOfRatings = updatedExcellentRating + updatedGoodRating + updatedAverageRating + updatedBadRating + updatedTerribleRating;

      const docRef = doc(db, 'pollTable', selectedRow.id);
      const newData = {
        excellentRating: updatedExcellentRating,
        goodRating: updatedGoodRating,
        averageRating: updatedAverageRating,
        badRating: updatedBadRating,
        terribleRating: updatedTerribleRating,
        numVotes: updatedNumVotes,
        evalType: 'quality',
        company: company
      };

      await setDoc(docRef, newData, { merge: true });

    } else {

      const ratingDocRef = doc(db, 'ratingTable', selectedRow.id);
      const ratingDocSnapshot = await getDoc(ratingDocRef);
      if (ratingDocSnapshot.exists()) {
        const ratingData = ratingDocSnapshot.data();
        selectedRatingData = ratingData;
      } else {
        console.log("Document does not exist!");
        return
      }

      let excellentRating = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`]) || 0;
      let goodRating = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV2`]) || 0;
      let averageRating = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV3`]) || 0;
      let badRating = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV4`]) || 0;
      let terribleRating = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV5`]) || 0;
      let numVotes = parseInt(selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`]) || 0;

      let updatedExcellentVotes = excellentRating / 100 * numVotes;
      let updatedGoodVotes = goodRating / 100 * numVotes;
      let updatedAverageVotes = averageRating / 100 * numVotes;
      let updatedBadVotes = badRating / 100 * numVotes;
      let updatedTerribleVotes = terribleRating / 100 * numVotes;
      let updatedNumVotes = numVotes;

      switch (value) {
        case 'Excellent':
          updatedExcellentVotes = updatedExcellentVotes + 1;
          break;
        case 'Good':
          updatedGoodVotes = updatedGoodVotes + 1;
          break;
        case 'Average':
          updatedAverageVotes = updatedAverageVotes + 1;
          break;
        case 'Bad':
          updatedBadVotes = updatedBadVotes + 1;
          break;
        case 'Terrible':
          updatedTerribleVotes = updatedTerribleVotes + 1;
          break;
        default: console.log("SWERR!");
      }
      updatedNumVotes++;
      const updatedExcellentRating = Math.round(updatedExcellentVotes * 100 / updatedNumVotes);
      const updatedGoodRating = Math.round(updatedGoodVotes * 100 / updatedNumVotes);
      const updatedAverageRating = Math.round(updatedAverageVotes * 100 / updatedNumVotes);
      const updatedBadRating = Math.round(updatedBadVotes * 100 / updatedNumVotes);
      const updatedTerribleRating = Math.round(updatedTerribleVotes * 100 / updatedNumVotes);

      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}`] = critName;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`] = updatedExcellentRating;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV2`] = updatedGoodRating;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV3`] = updatedAverageRating;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV4`] = updatedBadRating;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV5`] = updatedTerribleRating;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`] = updatedNumVotes;
      selectedRatingData.allEvalTargets[tarName][critNumber][`crit${critNumber}RO`] = 'quality';
      const docRef = doc(db, "ratingTable", selectedRow.id);
      await setDoc(docRef, selectedRatingData, { merge: true });
    }
    handleVoteComplete(selectedRow.id);
  }

  const handleAorBPollChange = async (event, type, critName, tarName, critNumber) => {
    const newVote = event.target.value;
    setLoading(true)
    let selectedData;
    let updatedOptionARating;
    let updatedOptionBRating;

    const pollDocRef = doc(db, 'pollTable', selectedRow.id);
    const pollDocSnapshot = await getDoc(pollDocRef);

    if (pollDocSnapshot.exists()) {
      selectedData = pollDocSnapshot.data();
    } else {
      console.log("Document does not exist!");
    }

    const { optionA, optionB, optionARating, optionBRating, numVotes } = selectedData; //Rating in %
    let optionARatingVotes = Math.round((optionARating / 100) * numVotes);
    let optionBRatingVotes = Math.round((optionBRating / 100) * numVotes);
    let updatedNumVotes = numVotes;

    if (newVote === optionA) {
      optionARatingVotes++;
    } else {
      optionBRatingVotes++;
    }
    updatedNumVotes++;
    updatedOptionARating = Math.round(
      (optionARatingVotes / updatedNumVotes) * 100
    );
    updatedOptionBRating = Math.round(
      (optionBRatingVotes / updatedNumVotes) * 100
    );


    // setYesRating(updatedYesRating);
    // setNoRating(updatedNoRating);
    const docRef = doc(db, "pollTable", selectedRow.id);
    const newData = {
      optionARating: updatedOptionARating,
      optionBRating: updatedOptionBRating,
      numVotes: updatedNumVotes,
      selectedRatingOption: "AorB",
      company: company
    };

    await setDoc(docRef, newData, { merge: true });
    handleVoteComplete(selectedRow.id);
  }

  const handleYesNoRatingChange = async (event, type, critName, tarName, critNumber) => {
    const newVote = event.target.value;
    setLoading(true)
    let selectedData;
    let updatedYesRating;
    let updatedNoRating;

    if (type === "rating") {
      const ratingDocRef = doc(db, 'ratingTable', selectedRow.id);
      const ratingDocSnapshot = await getDoc(ratingDocRef);

      if (ratingDocSnapshot.exists()) {
        const ratingData = ratingDocSnapshot.data();
        selectedData = ratingData;
      } else {
        console.log("Document does not exist!");
      }
      // const cNumVotes = `crit${critNumber}NumVotes`;
      let critYesVotes = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`]) || 0;
      let critNumVotes = parseInt(selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`]) || 0;

      const val1 = critYesVotes / 100;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}`] = critName;

      let yesRatingVotes = Math.round(val1 * critNumVotes);
      let noRatingVotes = critNumVotes - yesRatingVotes;

      if (newVote === "Yes") {
        yesRatingVotes++;
      } else {
        noRatingVotes++;
      }
      critNumVotes++;

      updatedYesRating = Math.round((yesRatingVotes / critNumVotes) * 100);
      updatedNoRating = Math.round((noRatingVotes / critNumVotes) * 100);
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}NumVotes`] = critNumVotes;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}RO`] = 'yesNo'
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV1`] = updatedYesRating;
      selectedData.allEvalTargets[tarName][critNumber][`crit${critNumber}ROV2`] = updatedNoRating;

      const docRef = doc(db, "ratingTable", selectedRow.id);
      await setDoc(docRef, selectedData, { merge: true });

    } else {

      const pollDocRef = doc(db, 'pollTable', selectedRow.id);
      const pollDocSnapshot = await getDoc(pollDocRef);

      if (pollDocSnapshot.exists()) {
        selectedData = pollDocSnapshot.data();
      } else {
        console.log("Document does not exist!");
      }

      const { yesRating, numVotes } = selectedData; //Rating in %
      let yesRatingVotes = Math.round((yesRating / 100) * numVotes);
      let noRatingVotes = numVotes - yesRatingVotes;
      let updatedNumVotes = numVotes;
      let updatedYesRating;
      let updatedNoRating;

      if (newVote === "Yes") {
        yesRatingVotes++;
      } else {
        noRatingVotes++;
      }
      updatedNumVotes++;
      updatedYesRating = Math.round(
        (yesRatingVotes / updatedNumVotes) * 100
      );
      updatedNoRating = Math.round(
        (noRatingVotes / updatedNumVotes) * 100
      );
      setYesRating(updatedYesRating);
      setNoRating(updatedNoRating);
      const docRef = doc(db, "pollTable", selectedRow.id);
      const newData = {
        yesRating: updatedYesRating,
        noRating: updatedNoRating,
        numVotes: updatedNumVotes,
        evalType: "yesNo", company: company
      };

      await setDoc(docRef, newData, { merge: true });
    }
    handleVoteComplete(selectedRow.id);
  }
  const printNotThere = () => {
    console.log("NOTHING!!!")
  }

  const renderRating = (selectedRow) => {
    const aRow = selectedRow
    return (
      <div>
        <table>
          <thead>
            {/* <tr>
                            <th sx={{ minWidth: '200px', fontSize: '20px', color: "#7286D3" }}>Rate below: </th>
                        </tr> */}
          </thead>
          <tbody>
            {Object.entries(aRow.allEvalTargets).map(
              ([targetName, targetItems], index) => (
                <tr key={index}>
                  <td sx={{ color: "#7286D3" }}>{targetName}</td>{" "}
                  {/* Display the target name */}
                  {targetItems.map((criteria, critIndex) => {
                    const crit = criteria[`crit${critIndex}`];
                    const critRO = criteria[`crit${critIndex}RO`];

                    if (crit !== null && critRO !== null) {
                      return (
                        <React.Fragment key={critIndex}>
                          <td style={{ color: "#7286D3" }}>{crit}</td>
                          <td>
                            {critRO === "yesNo" && (
                              <>
                                {/* {incrementHandledCrits(critNumber)} */}
                                {/* {setYesNoSelected(false)} */}
                                <FormControl>
                                  <Select
                                    variant="filled"
                                    style={{
                                      marginLeft: "10px",
                                      minWidth: "130px",
                                    }}
                                    defaultValue=""
                                    onChange={(event) =>
                                      handleYesNoRatingChange(
                                        event,
                                        'rating',
                                        crit,
                                        targetName,
                                        critIndex
                                      )
                                    }
                                  >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                            {critRO === "agreeDisagree" && (
                              <>
                                {/* {incrementHandledCrits(critNumber)} */}
                                {/* {setAgreeDisagreeSelected(false)} */}
                                <FormControl>
                                  <Select
                                    variant="filled"
                                    style={{
                                      minWidth: "130px",
                                      marginRight: "5px",
                                    }}
                                    defaultValue=""
                                    onChange={(event) =>
                                      handleAgreeDisagreeRatingChange(
                                        event,
                                        'rating', crit, targetName,
                                        critIndex
                                      )
                                    }
                                  >
                                    <MenuItem value="😃Agree">
                                      Agree
                                    </MenuItem>
                                    <MenuItem value="😞Disagree">
                                      Disagree
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                            {critRO === "quality" && (
                              <>
                                {/* {incrementHandledCrits(critNumber)} */}
                                {/* {setQualitySelected(false)} */}
                                <FormControl>
                                  <Select
                                    variant="filled"
                                    style={{
                                      minWidth: "130px",
                                    }}
                                    defaultValue=""
                                    onChange={(event) =>
                                      handleQualityRatingChange(
                                        event,
                                        'rating', crit, targetName,
                                        critIndex,
                                      )
                                    }
                                  >
                                    <MenuItem value="Excellent">
                                      😃Excellent
                                    </MenuItem>
                                    <MenuItem value="Good">🙂Good</MenuItem>
                                    <MenuItem value="Average">
                                      😐Average
                                    </MenuItem>
                                    /<MenuItem value="Bad">😔Bad</MenuItem>
                                    <MenuItem value="Terrible">
                                      😞Terrible
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}

                            {critRO === "5Stars" && (
                              <div>
                                <Rating
                                  value={starRating}
                                  onChange={(event) =>
                                    handleStarRatingChange(
                                      event.target.value,
                                      'rating', crit, targetName,
                                      critIndex
                                    )
                                  }
                                />
                                {/* <Rating
                                                                value={starRating}
                                                                onChange={(value) => handleStarRatingChange(value, critNumber)}
                                                            /> */}
                              </div>
                            )}
                            {critRO === "responsiveness" && (
                              <>
                                {/* {incrementHandledCrits(critNumber)} */}
                                {/* {setResponsivenessSelected(false)} */}
                                <FormControl>
                                  <Select
                                    variant="filled"
                                    style={{
                                      minWidth: "130px",
                                    }}
                                    Value=""
                                    onChange={(event) =>
                                      handleResponsivenessRatingChange(
                                        event,
                                        critIndex
                                      )
                                    }
                                  >
                                    <MenuItem value="Very Fast">
                                      🦅Very Fast
                                    </MenuItem>
                                    <MenuItem value="Fast">🐆Fast</MenuItem>
                                    <MenuItem value="Average">
                                      🦊Average
                                    </MenuItem>
                                    <MenuItem value="Slow">
                                      🐇Slow/
                                    </MenuItem>
                                    <MenuItem value="Very Slow">
                                      🐢Very Slow
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                          </td>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tr>
              )
            )}
            {/* <TableCell> */}
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              onClick={() => {
                handleVoteComplete(selectedRow.id);
              }}
              style={{
                border: "none",
                borderRadius: 10,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#5B76D7",
              }}
            >
              DONE
            </Button>
            {/* </TableCell> */}
          </tbody>
        </table>
      </div>
    );
  }

  const renderPoll = (row) => {
    switch (row.selectedRatingOption) {

      case 'AorB':
        return (
          <FormControl style={{ marginRight: '10px' }}>

            <Select
              //labelId="Select Yes or No:"
              // id="yes-no-select"
              variant="outlined"
              style={{ minWidth: '200px' }}
              defaultValue=''
              onChange={(event) => handleAorBPollChange(event, 'poll', null, null, null)}
            >
              <MenuItem value={row.optionA}>{row.optionA}</MenuItem>
              <MenuItem value={row.optionB}>{row.optionB}</MenuItem>
            </Select>
          </FormControl>
        )

      case 'yesNo':
        return (
          <FormControl style={{ marginRight: '10px' }}>

            <Select
              //labelId="Select Yes or No:"
              // id="yes-no-select"
              variant="outlined"
              style={{ minWidth: '200px' }}
              defaultValue=''
              onChange={(event) => handleYesNoRatingChange(event, 'poll', null, null, null)}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        )

      case 'agreeDisagree':
        return (
          <div style={{ marginRight: '10px' }}>
            <FormControl style={{ marginRight: '10px' }}>
              <Select
                variant="outlined"
                style={{ minWidth: '200px' }}
                defaultValue=''
                onChange={(event) => handleAgreeDisagreeRatingChange(event, 'poll', null, null, null)}
              >
                <MenuItem value="Agree">😃Agree</MenuItem>
                <MenuItem value="Disagree">😞Disagree</MenuItem>
              </Select>
            </FormControl>
          </div>
        )

      case 'quality':
        return (
          <div style={{ marginRight: '10px' }}>
            <FormControl style={{ marginRight: '10px' }}>
              <Select
                variant="outlined"
                style={{ minWidth: '200px' }}
                Value=''
                onChange={(event) => handleQualityRatingChange(event, 'poll', null, null, null)}
              >
                <MenuItem value="Excellent">😃Excellent</MenuItem>
                <MenuItem value="Good">🙂Good</MenuItem>
                <MenuItem value="Average">😐Average</MenuItem>
                <MenuItem value="Bad">😔Bad</MenuItem>
                <MenuItem value="Terrible">😞Terrible</MenuItem>
              </Select>
            </FormControl>
          </div>
        )

      case '5Stars':
        return (
          <div style={{ marginRight: "10px" }}>
            return
            <Rating
              value={starRating}
              onChange={(event) =>
                handleStarRatingChange(event.target.value, 'poll', null, null, null)
              }
            />
            {/* <Rating value={starRating}
                        onChange={(value) => handleStarRatingChange(value, critNumber)} />; */}
          </div>
        );

      case 'responsiveness':
        return (
          <FormControl style={{ marginRight: '10px' }}>
            <Select
              variant="outlined"
              style={{ minWidth: '200px' }}
              Value=''
              onChange={handleResponsivenessRatingChange()}
            >
              <MenuItem value="Very Fast">🦅Very Fast</MenuItem>
              <MenuItem value="Fast">🐆Fast</MenuItem>
              <MenuItem value="Average">🦊Average</MenuItem>
              <MenuItem value="Slow">🐇Slow/</MenuItem>
              <MenuItem value="Very Slow">🐢Very Slow</MenuItem>
            </Select>
          </FormControl>
        )

      default: console.log("SWERR!")
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    //labelId="Select Yes or No:"
    setPage(0);
  };

  const handleSort = (column) => {
    if (sort.column === column) {
      setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ column: column, order: "asc" });
    }

  };

  // const filterResults = (results, userId) => {

  //   if (userId === null || userId === '') {
  //     console.log("Null userId so returning empty array")
  //     return [];
  //   }
  //   return results.filter((row) => {
  //     return row.userId === userId;
  //   })
  // };
  const pendingVotesTableRef = collection(db, "pendingVotesTable");
  const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  };
  const fetchData = async (aTable) => {
    const today = Timestamp.now();

    // const today = Timestamp.fromDate(new Date());
    try {
      const pendingVotesTableQuery = query(pendingVotesTableRef);
      const pendingVotesTableSnapshot = await getDocs(pendingVotesTableQuery);
      const myPendingVotesTableData = pendingVotesTableSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => !doc.votesCompleted?.includes(userId));
      // const myPendingVotesTableData = filterResults(pendingVotesTableData, userId);

      const activeTableQuery = query(collection(db, aTable),
        where('status', '==', 'open'),
        where('company', '==', company),
        where('type', '==', 'privatePoll')
      );
      const activeTableSnapshot = await getDocs(activeTableQuery);

      const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const myActiveTableData = [];
      const processedIds = new Set();

      activeTableData.forEach((row) => {

        // const expDate = convertTimestampToDate(row.expiryDate);
        if (row.expiryDate >= today) {

          if (!processedIds.has(row.id)) {
            processedIds.add(row.id);
            const activeTableItem = myPendingVotesTableData.filter((item) => {
              const condition = item.pendingVotesTableId === row.id;
              return condition;
            });
            if (activeTableItem.length > 0) myActiveTableData.push(row);
          }
        } else {
          const docRef = doc(db, aTable, row.id);
          console.log("how did i get here")
          updateDoc(docRef, { status: 'closed' });
        }
      });
      return myActiveTableData;
    } catch (error) {
      if (userId === '') {
        alert("Login first")
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchAllData = async () => {
    setLoading(true)
    try {
      const pollTableData = await fetchData('pollTable');
      // const ratingTableData = await fetchData('ratingTable');
      // Combine data from both tables
      //const combinedData = [...pollTableData, ...ratingTableData];

      // Update state with combined data
      setPollResults(pollTableData);
    } catch (error) {
      setLoading(false)
      console.error("Error fetching all data:", error);
    }
  };

  useEffect(() => {
    fetchAllData()
  },
    [selectedQuestion, isDisabled, selectedRating, selectedRow, avgStarRating, isPollRateComplete, userId, voteCompleteId, showSelectForm, yesRating, noRating, agreeRating, disagreeRating, excellentRating, goodRating, badRating, averageRating, terribleRating]);

  const sortedResults = pollResults.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.column] > b[sort.column] ? 1 : -1;
    } else {
      return b[sort.column] > a[sort.column] ? 1 : -1;
    }
  });

  //change pollResults back to sortedResults.slice below, once the sorting error is fixed
  const dataToDisplay = pollResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function handleButtonClick(result, index) {
    setSelectedRow(result);
    setShowSelectForm(true);
    setIsDisabled(true);
  }

  function handleRatingCompleteChange() {
    setIsPollRateComplete(true)
  }

  return (
    <>
      <Box marginTop={2} >
        {/* <Typography color='grey' marginLeft='6px' fontFamily='monospace' marginBottom='0.75em'> YOUR POLLS:</Typography> */}
        <Grid container spacing={1} direction='row'>
          <Grid container spacing={6} direction='row'  >
            <Grid item xs={6}  >
              <Card
                elevation={10}
                sx={{ width: "1500px", marginLeft: '3px', marginRight: "10px" }}
              >
                {selectedRow && (
                  <Grid item xs={6}  >
                    <Card
                      sx={{ marginTop: '1em', marginBottom: '1em', width: '1200px', marginLeft: "12px", overflow: 'auto' }}
                    >
                      {showSelectForm ? (
                        <Stack direction='row' spacing='10px' marginTop='.5em' marginBottom='.5em'>
                          <Typography
                            align="justify"
                            sx={{
                              // marginLeft: "10px",
                              backgroundColor: "#F7F9F9",
                              fontSize: 24,
                              fontWeight: 'bold',
                              fontStyle: 'italic',
                              color: "#7286D3",
                              display: "flex",
                              wordWrap: "break-word",
                              // marginTop: 2,
                              width: "700px",
                              paddingLeft: "5px",
                              // paddingRight: "5px",
                              alignItems: "justify"
                              // overflowY: "scroll",
                            }}
                          >
                            {selectedRow.question || selectedRow.ratName}

                          </Typography>
                          {loading && (
                            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                              <CircularProgress color="primary" size={50} />
                            </Box>
                          )}
                          {/* {(selectedRow.type === 'poll') ? renderPoll(selectedRow) : renderRating(selectedRow)} */}
                          {/* {isPollRateComplete && handleVoteComplete(selectedRow.id)} */}

                        </Stack>
                      ) : null}
                    </Card>
                  </Grid>
                )}
                <TableContainer
                  component={Paper}
                  sx={{ overflow: "auto", marginLeft: '14px', marginTop: "0.5em" }}
                >
                  <Table size="small" sx={{ width: "85%" }}>
                    <TableHead
                      sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                    >
                      <TableRow >
                        {/* <TableCell
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        width: "5%",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                    }}
                                                >
                                                    Poll #
                                                    {sort.order === "asc" ? (
                                                        <ArrowDropUp
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("ID")}
                                                        />
                                                    ) : (
                                                        <ArrowDropDown
                                                            sx={{ color: "white" }}
                                                            onClick={() => handleSort("ID")}
                                                        />
                                                    )}
                                                </TableCell> */}
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "10%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          UserID
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("userId")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("userId")}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            width: "10%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Category
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("selectedCategory")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("selectedCategory")}
                            />
                          )}
                        </TableCell> */}
                        <TableCell
                          sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            width: "70%",
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Poll/Rating Question
                          {sort.order === "asc" ? (
                            <ArrowDropUp
                              sx={{ color: "white" }}
                              onClick={() => handleSort("question")}
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "white" }}
                              onClick={() => handleSort("question")}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="justify"
                          sx={{
                            width: "10%",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            color: "white",
                          }}>
                          {/* This title-less column has the Vote button */}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}
                    >
                      {dataToDisplay.map((result, index) => {
                        const value = result.question || result.ratName
                        if (!uniqueValues.has(value)) {
                          uniqueValues.add(value) // Add value to the set
                          return (
                            < TableRow hover key={index} >

                              <TableCell>{result.userId}</TableCell>
                              {/* <TableCell>{result.selectedCategory}</TableCell> */}
                              <TableCell
                                align="justify"
                                sx={{ whiteSpace: "nowrap" }}
                              >

                                {result.question || result.ratName}
                              </TableCell>

                              <TableCell>
                                <Button
                                  variant="contained"
                                  backgroundcolor="#333"
                                  // endIcon={<SendIcon backgroundColor="grey" />}
                                  onClick={() => {
                                    handleButtonClick(result, index)
                                  }}
                                  style={{
                                    border: "none",
                                    borderRadius: 10,
                                    fontWeight: 'bold',
                                    color: "white",
                                    backgroundColor: "#5B76D7"
                                  }}>
                                  VOTE
                                </Button>

                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={sortedResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default PollInvitesPrivate
