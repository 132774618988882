import React, { useState, useEffect } from "react";

import {
    Card,
    Alert,
    Box,
    Popover,
    Tooltip,
    Typography,
    TextField,
    Grid,
    Stack,
    Autocomplete,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Rating,
    CardContent,
} from "@mui/material";

import DBOpenQuizResultsPrivate from "./DBOpenQuizResultsPrivate.js";
import DBClosedQuizResultsPrivate from "./DBClosedQuizResultsPrivate.js";
import NewQuizPrivate from "./NewQuizPrivate.js";
import { db } from "../config/firebase.js";

function ProCorpPrivateQuizAdmin({ userId, onLogin, onLogout, institution, admin }) {
    // const { userId, db } = props;
    const [showStartNewQuiz, setShowStartNewQuiz] = React.useState(false);
    const [openQuizResults, setShowOpenQuizResults] = React.useState(true);
    const [closedQuizResults, setShowClosedQuizResults] = React.useState(false);

    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(2)

    const handleStartNewQuiz = () => {
        setShowStartNewQuiz(!showStartNewQuiz);
        setShowOpenQuizResults(false);
        setShowClosedQuizResults(false);

        if (!(selectedButton === 1)) {
            setSelectedButton(1);
        } else {
            setSelectedButton(0);
        }
    };

    const handleOpenQuizResults = () => {
        setShowOpenQuizResults(!openQuizResults);
        setShowStartNewQuiz(false);
        setShowClosedQuizResults(false);
        if (!(selectedButton === 2)) {
            setSelectedButton(2);
        } else {
            setSelectedButton(0);
        }
    };

    const handleClosedQuizResults = () => {
        setShowClosedQuizResults(!closedQuizResults);
        setShowStartNewQuiz(false);
        setShowOpenQuizResults(false);

        if (!(selectedButton === 3)) {
            setSelectedButton(3);
        } else {
            setSelectedButton(0);
        }
    };

    return (
        <>
            <Box marginTop="4px" marginLeft="4px">
                <Stack spacing={4} direction="row">
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleStartNewQuiz();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "40px",
                            marginLeft: "2px",
                            marginTop: "8px",
                            marginBottom: "16px",
                            borderRadius: 7,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            // backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        START NEW
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenQuizResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "40px",
                            marginLeft: "4px",
                            marginTop: "8px",
                            marginBottom: "16px",
                            borderRadius: 7,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN QUIZZES
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedQuizResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "40px",
                            marginLeft: "4px",
                            marginTop: "8px",
                            marginBottom: "px",
                            borderRadius: 7,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 3 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 3 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED QUIZZES
                    </Button>

                </Stack>
                {showStartNewQuiz && <NewQuizPrivate userId={userId} db={db} institution={institution} />}
                {openQuizResults && <DBOpenQuizResultsPrivate userId={userId} db={db} institution={institution} />}
                {closedQuizResults && <DBClosedQuizResultsPrivate userId={userId} db={db} institution={institution} />}
            </Box>
        </>
    );
}

export default ProCorpPrivateQuizAdmin;
