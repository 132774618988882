import React, { useState, useEffect } from "react";

import {
    Card,
    Alert,
    Box,
    Popover,
    Tooltip,
    Typography,
    TextField,
    Grid,
    Stack,
    Autocomplete,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Rating,
    CardContent,
} from "@mui/material";

import DBOpenQuizResultsPrivate from "./DBOpenQuizResultsPrivate";
import DBClosedQuizResultsPrivate from "./DBClosedQuizResultsPrivate";
import DBOpenRateResults from "./DBOpenRateResults";
import DBClosedRateResults from "./DBClosedRateResults";
import QuizInvitesPrivate from "./QuizInvitesPrivate";

function ProCorpPrivateQuizUser(props) {
    const { userId, db, company, institution, admin } = props;
    const [openQuizResults, setShowOpenQuizResults] = React.useState(true);
    const [showQuizInvitations, setShowQuizInvitations] = React.useState(false);
    const [closedRateResults, setShowClosedRateResults] = React.useState(false);
    const [closedQuizResults, setShowClosedQuizResults] = React.useState(false);
    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(2)

    const handleQuizInvitations = () => {
        setShowQuizInvitations(!showQuizInvitations);
        setShowOpenQuizResults(false);
        setShowClosedQuizResults(false);

        if (!(selectedButton === 1)) {
            setSelectedButton(1);
        } else {
            setSelectedButton(0);
        }
    };
    const handleOpenQuizResults = () => {
        setShowOpenQuizResults(!openQuizResults);
        setShowClosedQuizResults(false);
        setShowQuizInvitations(false);

        if (!(selectedButton === 2)) {
            setSelectedButton(2);
        } else {
            setSelectedButton(0);
        }
    };

    const handleClosedQuizResults = () => {
        setShowClosedQuizResults(!closedQuizResults);
        setShowOpenQuizResults(false);
        setShowQuizInvitations(false);

        if (!(selectedButton === 3)) {
            setSelectedButton(3);
        } else {
            setSelectedButton(0);
        }
    };

    return (
        <>
            <Box marginTop={3} marginLeft="4px">
                <Stack spacing={4} direction="row">
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleQuizInvitations();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "2px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none"
                        }}
                    >
                        QUIZ INVITATIONS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenQuizResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN QUIZZES
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedQuizResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 3 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 3 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED QUIZZES
                    </Button>

                </Stack>
                {showQuizInvitations && <QuizInvitesPrivate userId={userId} db={db} institution={institution} admin={admin} />}
                {openQuizResults && <DBOpenQuizResultsPrivate userId={userId} db={db} admin={admin} institution={institution} />}
                {closedQuizResults && <DBClosedQuizResultsPrivate userId={userId} db={db} institution={institution} admin={admin} />}
            </Box>
        </>
    );
}

export default ProCorpPrivateQuizUser;
