import React from 'react';
import { Stack, Rating, Card } from "@mui/material"
import GradeIcon from '@mui/icons-material/Grade';

export const RatingDisplay = ({ value, onChange }) => {

  return (
    <div style={{ align: 'center', whiteSpace: "nowrap" }}>
      <Rating
        value={value}
        onChange={onChange}
        precision={0.5}
        size='medium'
        icon={<GradeIcon fontSize='inherit' sx={{ color: "#5B76D7" }} />}
        emptyIcon={<GradeIcon fontSize='inherit' />}>
      </Rating>
    </div>
  );
};

export const CriteriaDisplay = ({ criteria, value, onChange }) =>
(
  <div>

    <Card
      elevation={10}
      sx={{
        align: 'centre',
        fontSize: "11pt",
        fontFamily: "inherit",
        // textAlign: 'center',
        marginTop: ".75em",
        marginBottom: "1em",
        marginLeft: "px",
        marginRight: "1px",
        minHeight: "110px",
        width: "230px",
        color: 'grey',

      }}>
      <h4 sx={{
        fontVariant: "normal",
        textTransform: "none",
        fontWeight: "normal",
        marginBottom: '1em'
      }}>{criteria}</h4>
      <RatingDisplay value={value} onChange={onChange} />
    </Card>
  </div>
);
