import React, { useState, useEffect } from "react";
import {
  Card,
  Tooltip,
  Typography,
  TextField,
  Grid,
  Stack,
  Shadows,
  Alert,
  Button,
  Paper,
  Dialog, DialogTitle, DialogContent, DialogActions
} from "@mui/material";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styled from "@emotion/styled";
import Pro from "./Pro";
import enzo from "./enzo.jpg";
import PollResults from "./PollResults.js";
// import YourEvaluations from "./YourEvaluations";
import PollInvites from "./PollInvites";
import NewPollsandRatings from "./NewPollsandRatings";

const button1Color = "#5B76D7";
const button2Color = "#f5f5f5";
const buttonSelectedColor = "#af52bf";
const useStyles = styled((theme) => ({
  selected: { backgroundColor: buttonSelectedColor, color: "white" },
  notSelected: { backgroundColor: button2Color, color: "grey" },
}));

function Options({userId, db, onLogin, onLogout}) {

  const [selectedButton, setSelectedButton] = useState(1);
  // const buttonClassFormat = useStyles();
  const [activeOption, setActiveOption] = useState("pro");
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [opinion, setOpinion] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);
  const [uId, setUId] = useState(userId);
  // const [db, setDb] = useState(props.db);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setUId(userId);
  }, [uId, selectedButton]);

  const handleButtonClick = (index) => {
    if (selectedButton === index) {
      setSelectedButton(null);
    } else {
      setSelectedButton(index);
    }
    if ((index === 3 || index === 4) && (!showAlert)) {
      setShowAlert(true)
    }
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <Stack
        spacing={2}
        direction="row"
        marginLeft={1.1}
        marginTop={4}
        marginBottom={1}
      >
        <Button
          variant="contained"
          onClick={() => handleButtonClick(1)}
          style={{
            border: "none",
            width: "100px",
            height: "100px",
            backgroundColor:
              selectedButton === 1 ? buttonSelectedColor : button2Color,
            color: selectedButton === 1 ? "white" : "grey",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            textTransform: "none",
          }}
        >
          RATE LAWMAKERS
        </Button>
        <Button
          variant="contained"
          onClick={() => handleButtonClick(2)}
          style={{
            border: "none",
            width: "100px",
            height: "100px",
            fontFamily: "'Open Sans', sans-serif",
            backgroundColor:
              selectedButton === 2 ? buttonSelectedColor : button2Color,
            color: selectedButton === 2 ? "white" : "grey",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            // boxShadow: "80%",
            textTransform: "none",
            zIndex: 1,
          }}
        >
          POLL RESULTS
        </Button>
        <Button
          variant="contained"
          onClick={() => handleButtonClick(3)}
          style={{
            border: "none",
            width: "100px",
            height: "100px",
            fontFamily: "'Open Sans', sans-serif",
            backgroundColor:
              selectedButton === 3 ? buttonSelectedColor : button2Color,
            color: selectedButton === 3 ? "white" : "grey",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            boxShadow: "80%",
            textTransform: "none",
            zIndex: 2,
          }}
        >
          POLL INVITATIONS
        </Button>

        <Button
          variant="contained"
          onClick={() => handleButtonClick(4)}
          style={{
            border: "none",
            width: "100px",
            height: "100px",
            fontFamily: "'Open Sans', sans-serif",
            backgroundColor:
              selectedButton === 4 ? buttonSelectedColor : button2Color,
            color: selectedButton === 4 ? "white" : "grey",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            boxShadow: "80%",
            textTransform: "none",
            zIndex: 3,
          }}
        >
          START POLL
        </Button>
      </Stack>
      {selectedButton === 1 && <Pro userId={userId} db={db} />}
      {selectedButton === 2 && <PollResults userId={userId} db={db} />}
      {userId ? (
        <>
          {selectedButton === 3 && <PollInvites userId={userId} db={db} />}
          {selectedButton === 4 && <NewPollsandRatings userId={userId} db={db} />}
        </>
      ) : (
        showAlert && (
          <Alert severity="info" action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }>
            <span style={{ fontStyle: 'italic' }}>Login to start a new poll, vote...</span>
          </Alert>)
      )}
    </React.Fragment>
  )
}

export default Options;
