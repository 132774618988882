import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  FormControl,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Rating from "@mui/material/Rating";
import { Timestamp, collection, doc, where, query, arrayUnion, updateDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"

function QuizInvitesPrivate(props) {

  const [isQuizRateComplete, setIsQuizRateComplete] = useState(false);
  const { userId, db, admin, institution, } = props;
  const [critNumber, setCritNumber] = useState(0);
  const [voteCompleteId, setVoteCompleteId] = useState('');

  const [currentRatingName, setCurrentRatingName] = useState('');

  const [selectedQuestion, setSelectedQuestion] = React.useState('');
  const [selectedRating, setSelectedRating] = React.useState('');

  const [name, setName] = useState('');
  const [quizTitle, setQuizTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [quizResults, setQuizResults] = useState([]);
  const [answers, setAnswers] = useState([])
  const [userResponses, setUserResponses] = useState([])

  const [ratingResults, setRatingResults] = React.useState([]);

  const [sort, setSort] = useState({ column: "count", order: "desc" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  // const [popoverOpen, setPopoverOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const [starRating, setStarRating] = React.useState(0);
  const [yesRating, setYesRating] = React.useState(0);
  const [noRating, setNoRating] = React.useState(0);
  const [agreeRating, setAgreeRating] = React.useState(0);
  const [disagreeRating, setDisagreeRating] = React.useState(0);
  const [excellentRating, setExcellentRating] = React.useState(0);
  const [goodRating, setGoodRating] = React.useState(0);
  const [averageRating, setAverageRating] = React.useState(0);
  const [badRating, setBadRating] = React.useState(0);
  const [terribleRating, setTerribleRating] = React.useState(0);
  const [showSelectForm, setShowSelectForm] = React.useState(true);
  // const [numVotes, setNumVotes] = React.useState(0);
  const [avgStarRating, setAvgStarRating] = React.useState(0);
  let uniqueValues = new Set();
  // const [ratTableData, setRatTableData] = useState([initializeRatTableRow()]);
  // const ratTableStartIndex = 0;
  // const ratTableEndIndex = ratTableData.length - 1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  async function updateTable(collectionName, updatedResp, min, max, median, mean) {
    const q = query(collection(db, collectionName),
      where('name', '==', name),
      where('quizTitle', '==', quizTitle));

    const querySnapshot = await getDocs(q);

    const docSnapshot = querySnapshot.docs[0];
    const docRef = doc(db, collectionName, docSnapshot.id);

    await updateDoc(docRef, {
      userResponses: updatedResp,
      min: min,
      max: max,
      median: median,
      mean: mean
    })
    handleVoteComplete(docSnapshot.id);


    // if (querySnapshot.exists) {
    // const docSnapshot = querySnapshot.docs[0];
    // const docRef = doc(db, 'collectionName', docSnapshot.id);

    // await updateDoc(docRef, {
    //   userResponse: data
    // });

    // } else {
    //   console.log("No document found with the given name.");
    // }
  }

  function calculateMin(updatedResp, numResps) {
    let numbers = new Set();
    updatedResp.map((resp) => {
      numbers.add(resp.score)
    })
    return Math.min(...numbers);
  }
  function calculateMax(updatedResp, numResps) {
    let numbers = new Set();
    updatedResp.map((resp) => {
      numbers.add(resp.score)
    })
    return Math.max(...numbers);
  }
  function calculateMedian(updatedResp, numResps) {
    let numbers = [];
    updatedResp.map((resp) => {
      numbers.push(resp.score)
    })
    const sortedNumbers = numbers.slice().sort((a, b) => a - b);
    const middleIndex = Math.floor(sortedNumbers.length / 2);

    if (sortedNumbers.length % 2 !== 0) {
      return sortedNumbers[middleIndex];
    }
    return (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
  }
  function calculateMean(updatedResp, numResps) {
    let totalScore = 0;
    let avgScore = 0;
    updatedResp.map((resp) => {
      totalScore += resp.score
    })
    avgScore = Math.ceil(totalScore / numResps)
    return avgScore
  }

  const handleSub = () => {

    if (quizResults.length === 0) {
      setShowSelectForm(false)
      return;
    }

    let scoreCount = 0;
    let newScore = 0;
    let numQuestions = 0;

    // if (questions === null) return;

    let newUserResponses = { userId: '', answers: [], score: 0 }

    questions.map((entry, index) => {
      if (entry.correctAnswer !== '') {
        numQuestions++;
        if (answers[index] === entry.correctAnswer) scoreCount++;
      }
      newScore = scoreCount * 100 / numQuestions
    })

    newUserResponses.userId = userId;
    newUserResponses.answers = answers;
    newUserResponses.score = newScore;
    let updatedResp = [...userResponses, newUserResponses]
    setUserResponses(updatedResp)
    const numResps = updatedResp.length

    let mean = calculateMean(updatedResp, numResps)
    let median = calculateMedian(updatedResp, numResps)
    let min = calculateMin(updatedResp, numResps)
    let max = calculateMax(updatedResp, numResps)

    updateTable('quizTable', updatedResp, min, max, median, mean)

  }

  const handleChange = (index, field, value) => {
    const newQuizResults = quizResults;

    newQuizResults.questions[index][field] = value;
    const newAnswers = [...answers];
    newAnswers[index] = value
    setAnswers(newAnswers);
    setQuestions(newQuizResults.questions);
    //setQuizResults(newQuizResults);
  };

  const handleVoteComplete = async (id) => {
    if (userId === 'rpaplus') {
      setVoteCompleteId(id);
      setShowSelectForm(false)
      return
    }

    const q = query(collection(db, 'pendingVotesTable'), where('pendingVotesTableId', '==', id));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, 'pendingVotesTable', document.id);
      await updateDoc(docRef, {
        votesCompleted: arrayUnion(userId)
      });
    });
    setVoteCompleteId(id);
    setShowSelectForm(false)
  };

  const pendingVotesTableRef = collection(db, "pendingVotesTable");
  const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  };
  const fetchData = async (aTable) => {
    const today = Timestamp.now();

    // const today = Timestamp.fromDate(new Date());
    try {
      const pendingVotesTableQuery = query(pendingVotesTableRef);
      const pendingVotesTableSnapshot = await getDocs(pendingVotesTableQuery);
      const myPendingVotesTableData = pendingVotesTableSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => !doc.votesCompleted?.includes(userId));
      // const myPendingVotesTableData = filterResults(pendingVotesTableData, userId);

      const activeTableQuery = query(collection(db, aTable),
        where('status', '==', 'open'),
        where('name', '==', institution),
        where('type', '==', 'privateQuiz')
      );
      const activeTableSnapshot = await getDocs(activeTableQuery);

      const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const myActiveTableData = [];
      const processedIds = new Set();

      activeTableData.forEach((row) => {

        // const expDate = convertTimestampToDate(row.expiryDate);
        if (row.expiryDate >= today) {

          if (!processedIds.has(row.id)) {
            processedIds.add(row.id);

            const activeTableItem = myPendingVotesTableData.filter((item) => {
              const condition = item.pendingVotesTableId === row.id;

              return condition;
            });

            if (activeTableItem.length > 0) myActiveTableData.push(row);

          }
        } else {
          const docRef = doc(db, aTable, row.id);
          console.log("how did i get here")
          updateDoc(docRef, { status: 'closed' });
        }
      });
      return myActiveTableData[0];
    } catch (error) {
      if (userId === '') {
        alert("Login first")
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchAllData = async () => {

    try {
      const quizTableData = await fetchData('quizTable');

      if (quizTableData === undefined) {
        setShowSelectForm(false)
        return
      }


      if (quizTableData != null) {
        setQuizResults(quizTableData);
        setName(quizTableData.name);
        setQuizTitle(quizTableData.quizTitle)
        setQuestions(quizTableData.questions);
        if (quizTableData.userResponses !== undefined)
          setUserResponses(quizTableData.userResponses)
      }
    } catch (error) {
      console.error("Error fetching all data:", error);
    }
  };

  useEffect(() => {
    fetchAllData()
  },
    [isQuizRateComplete, voteCompleteId, showSelectForm]
  );

  return (
    showSelectForm && (
      <Card elevation={10} sx={{ marginTop: '4px', marginBottom: '6px', width: "1000px", padding: "6px" }}>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'left', color: 'blue', width: "900px", padding: "6px" }}> {quizTitle} </Typography>
        <Card elevation={10} sx={{ marginBottom: '6px', width: "950px", padding: "6px" }}>

          {questions.map((quest, index) => (
            quest.question ? (
              <Grid container spacing={.25} key={index} variant="outlined" style={{ textAlign: 'left', marginTop: '4px' }}>
                <Grid item xs={10}>
                  <div key={index}>
                    {quest.question}
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth sx={{ minWidth: 100 }}  >
                    <Select
                      value={answers[index]}
                      onChange={(e) => handleChange(index, 'answer', e.target.value)}
                      defaultValue=''
                      //displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>Select the correct answer</MenuItem>
                      <MenuItem value="✔️Agree">✔️Agree</MenuItem>
                      <MenuItem value="❌Disagree">❌Disgree</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : null
          ))}
          <Button
            variant="contained"
            backgroundcolor="#333"
            // endIcon={<SendIcon backgroundColor="grey" />}
            onClick={handleSub}
            style={{
              border: "none",
              width: "130px",
              marginLeft: "8px",
              marginBottom: '3px',
              borderRadius: 28,
              color: "black",
              backgroundColor: "#D1D1D1",
            }}
          >
            SUBMIT
          </Button>
        </Card>
      </Card >
    )
  )
}

export default QuizInvitesPrivate
