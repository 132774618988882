import React, { useState, useEffect } from "react";

import {
  Card,
  Box,
  Typography,
  Grid,
  TextField,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress';

import {
  ratingOptionsEdu,
} from "./MyData";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { collection, where, query, addDoc, getDocs } from "firebase/firestore"

function NewQuizPrivate(props) {
  const { userId, db, institution } = props;
  const [loading, setLoading] = React.useState(false);
  // const [userId, setUserId] = useState(props.userId);
  // const [text, setText] = useState('');
  // const [selectedOption, setSelectedOption] = useState('');
  // const [correctOption, setCorrectOption] = useState('');

  const [questions, setQuestions] = useState(
    Array.from({ length: 10 }).map(() => ({
      question: '',
      correctAnswer: ''
    }))
  );
  const handleChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  const [quizTitle, setQuizTitle] = React.useState("");
  // const options = ['Option 1', 'Option 2', 'Option 3'];

  // const [question, setQuestion] = React.useState("");
  const [showForm, setShowForm] = React.useState(true)
  const [binaryRespQuestFlag, setBinaryRespQuestFlag] = React.useState(false)

  const [QuizResults, setQuizResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedRatingOption, setSelectedRatingOption] = React.useState(null);
  const [shownSelectedRatingOption, setShownSelectedRatingOption] =
    React.useState(null);
  const [optionA, setOptionA] = React.useState("");
  const [optionB, setOptionB] = React.useState("")

  const [questCategorySelected, setQuestCategorySelected] = React.useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const handleExpiryDateChange = (datePicked) => {
    let date = datePicked.toDate();
    setExpiryDate(date);
  };

  // const handleExpiryDateChange = (date) => {
  //   const expiryString = date.toISOString();
  //   setExpiryDate(expiryString);
  // };

  const filterResults = (results) => {
    return results.filter((row) => row.userId === props.userId);
  };

  useEffect(() => {

  }, [expiryDate]);

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // let quest = question;

    // if (selectedRatingOption === 'AorB') {
    //   quest = optionA + "   or   " + optionB + "?"
    // }
    // const expiryDate = new Date();
    const quizTableData = {
      quizTitle: quizTitle,
      userId: userId,
      questions: questions,
      // question: quest,
      // optionA,
      // optionB,
      // selectedRatingOption: selectedRatingOption,
      expiryDate: expiryDate,
      // optionARating: 0,
      // optionBRating: 0,
      // yesRating: 0,
      // noRating: 0,
      // agreeRating: 0,
      // disagreeRating: 0,
      // numVotes: 0,
      // excellentRating: 0,
      // goodRating: 0,
      // averageRating: 0,
      // badRating: 0,
      // terribleRating: 0,
      // averageStarRating: 0,
      status: 'open',
      type: 'privateQuiz',
      name: institution,
    };

    addEntryToTable('quizTable', quizTableData);

    const quizTableQuery = query(collection(db, 'quizTable'),
      where('name', '==', institution),
      where('status', '==', 'open'),
      where('quizTitle', '==', quizTitle));

    const activeQuizTableSnapshot = await getDocs(quizTableQuery);
    const quizTableId = activeQuizTableSnapshot.docs[0].id;
    const pendingVotesTableData = {
      pendingVotesTableId: quizTableId,
      userId: userId
    }
    addEntryToTable('pendingVotesTable', pendingVotesTableData);
    setQuestCategorySelected(false);
    setSelectedCategory("");
    setShownSelectedRatingOption(null);
    setQuestions(Array.from({ length: 10 }).map(() => ({
      question: '',
      correctAnswer: ''
    })));
    setExpiryDate(new Date());
    setShowForm(false)
    setBinaryRespQuestFlag(false)
    setLoading(false);
  };

  function handleChangeQuizTitle(title) {
    setQuizTitle(title)
  }

  return (
    <>
      {showForm && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card elevation={10} sx={{ width: "1100px", padding: "6px" }}>
              <div style={{ display: "flex", alignItems: "left", minWidth: '250px' }}>
                <FormControl fullWidth sx={{ minWidth: 200 }}>
                  <TextField
                    label={`Enter quiz title`}
                    variant="outlined"
                    rows={1}
                    value={quizTitle}
                    onChange={(e) => handleChangeQuizTitle(e.target.value)}
                  />
                </FormControl>
              </div>
              {questions.map((row, index) => (
                <Grid container spacing={.25} key={index} style={{ marginTop: '4px' }}>
                  <Grid item xs={10}>
                    <FormControl fullWidth sx={{ minWidth: 300 }}>
                      <TextField
                        label={`Enter question ${index + 1}...`}
                        variant="outlined"
                        rows={1}
                        value={row.question}
                        onChange={(e) => handleChange(index, 'question', e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth sx={{ minWidth: 100 }} >
                      <Select
                        value={row.correctAnswer}
                        onChange={(e) => handleChange(index, 'correctAnswer', e.target.value)}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>Select the correct answer</MenuItem>
                        {ratingOptionsEdu.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={2}>
                    <FormControl fullWidth sx={{ minWidth: 100 }}>
                      <TextField
                        label={`Pick correct answer`}
                        variant="outlined"
                        rows={1}
                        value={row.answer}
                        onChange={(e) => handleChange(index, 'answer', e.target.value)}
                      />
                    </FormControl> */}
                  {/* <Select
                      value={row.correctOption}
                      onChange={(e) => handleChange(index, 'correctOption', e.target.value)}
                      displayEmpty
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>Select Correct Option</MenuItem>
                      {ratingOptionsEdu.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select> */}
                </Grid>
              ))}
              <Stack spacing={.75} direction='row' style={{ marginTop: '4px' }}>
                {/* <Grid item xs={2}> */}
                <FormControl sx={{ minWidth: 70 }}>
                  <DatePicker
                    label="Closing Date"
                    onChange={(value) => handleExpiryDateChange(value)}
                    sx={{ width: "200px" }}
                    clearText="Clear"
                    cancelText="Cancel"
                    todayText="Today"
                    showTodayButton
                  />
                </FormControl>
                <Button variant="contained" width="70px" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
                {loading && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', height: '5vh' }}>
                    <CircularProgress color="primary" size={75} />
                  </Box>
                )}
              </Stack>
            </Card>
          </LocalizationProvider >
        </>
      )
      }
    </>
  );
}
export default NewQuizPrivate;
