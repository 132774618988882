import React, { useState, useEffect, } from 'react'
import {
    Card,
    Box,
    Typography,
    TextField,
    Grid,
    Stack,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Select,
    CardContent,
} from "@mui/material";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Rating from "@mui/material/Rating";
// import axios from "axios";

import { collection, doc, where, query, updateDoc, deleteDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"
import { CriteriaDisplay, RatingDisplay } from "./Ratings";

function DBClosedPollResultsPrivate(props) {
    const { userId, db, company } = props;
    const [pollResults, setPollResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    const [starRating, setStarRating] = React.useState(0);
    const [yesRating, setYesRating] = React.useState(0);
    const [noRating, setnoRating] = React.useState(0);
    const [agreeRating, setAgreeRating] = React.useState(0);
    const [disagreeRating, setDisagreeRating] = React.useState(0);
    const [excellentRating, setExcellentRating] = React.useState(0);
    const [goodRating, setGoodRating] = React.useState(0);
    const [averageRating, setAverageRating] = React.useState(0);
    const [badRating, setBadRating] = React.useState(0);
    const [terribleRating, setTerribleRating] = React.useState(0);
    const [showSelectForm, setShowSelectForm] = React.useState(false);
    const [numVotes, setNumVotes] = React.useState(0);
    const [averageStarRating, setAverageStarRating] = React.useState(0);
    const [sort, setSort] = useState({ column: "numVotes", order: "desc" });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (column) => {
        if (sort.column === column) {
            setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
        } else {
            setSort({ column: column, order: "asc" });
        }
    };
    // const filterResults = (results, userId) => {
    //     return results.filter((row) => row.userId === userId)
    // }
    const fetchData = async () => {
        try {
            const activeTableQuery = query(collection(db, 'pollTable'),
                where('status', '==', 'closed'),
                where('company', '==', company),
                where('type', '==', 'privatePoll'));
            const activeTableSnapshot = await getDocs(activeTableQuery);

            const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPollResults(activeTableData);
        } catch (error) {
            if (userId === '') {
                alert("Login first")
            } else {
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        resetSort()
        fetchData();
    }, [averageStarRating, selectedRow, userId, showSelectForm, numVotes, yesRating, noRating, excellentRating, goodRating, badRating, averageRating, terribleRating]);

    const sortedResults = pollResults.sort((a, b) => {
        if (sort.order === "asc") {
            return a[sort.column] > b[sort.column] ? 1 : -1;
        } else {
            return b[sort.column] > a[sort.column] ? 1 : -1;
        }
    });
    const dataToDisplay = sortedResults.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    function resetSort() {
        setSort({ column: "numVotes", order: "desc" });
    }
    function handleButtonClick(result) {
        setSelectedRow(result);
        setShowSelectForm(true);
    }
    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options);
    }
    return (
        <Box marginTop={2} marginLeft='10px' >
            {/* <Typography color='grey' marginLeft='16px' fontFamily='monospace' marginBottom='0.75em'> Active Poll Results:</Typography> */}
            <Grid container spacing={1} direction='row'>
                <Grid container spacing={9} direction='row'  >
                    <Grid item xs={6}  >
                        <Card
                            elevation={10}
                            sx={{ width: "1350px", marginRight: "20px" }}
                        >
                            <TableContainer
                                component={Paper}
                                sx={{ overflow: "auto", marginLeft: '10px', marginTop: "0.5em" }}
                            >
                                <Table size="small" sx={{ width: "99%" }}>
                                    <TableHead
                                        sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                                    >
                                        <TableRow >
                                            {/* <TableCell
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    width: "5%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Poll #
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("ID")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("ID")}
                                                    />
                                                )}
                                            </TableCell> */}
                                            {/* <TableCell
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    width: "10%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                UserID
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("userId")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("userId")}
                                                    />
                                                )}
                                            </TableCell> */}
                                            <TableCell
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-all",
                                                    width: "60%",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Question
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("question")}
                                                    />
                                                )}

                                            </TableCell>

                                            <TableCell sx={{ whiteSpace: "break-word", width: "15%", fontWeight: "bold", color: "white" }}>
                                                Poll Results
                                                {/* {sort.order === "asc" ? (
                                                    <ArrowDropUp sx={{ color: "white" }} onClick={() => handleSort("Poll Results")} />) : (
                                                    <ArrowDropDown sx={{ color: "white" }} onClick={() => handleSort("Poll Results")} />
                                                )} */}
                                            </TableCell>
                                            <TableCell
                                                align="justify"
                                                sx={{
                                                    width: "10%",
                                                    whiteSpace: "nowrap",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                # Votes
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("numVotes")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("numVotes")}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell
                                                align="justify"
                                                sx={{
                                                    width: "30%",
                                                    whiteSpace: "nowrap",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}>
                                                End Date
                                                {sort.order === "asc" ? (
                                                    <ArrowDropUp
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("expiryDate")}
                                                    />
                                                ) : (
                                                    <ArrowDropDown
                                                        sx={{ color: "white" }}
                                                        onClick={() => handleSort("expiryDate")}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}
                                    >
                                        {dataToDisplay.map((result, index) => (

                                            < TableRow hover key={index} >
                                                {/* <TableCell>{result.id}</TableCell> */}
                                                <TableCell
                                                    align="justify"
                                                    sx={{ whiteSpace: "normal" }}
                                                // onClick={(event) => PopoverHandler(event, result)}
                                                >
                                                    {result.question}
                                                </TableCell>

                                                <TableCell >
                                                    {(() => {
                                                        switch (result.selectedRatingOption) {
                                                            case 'AorB':
                                                                return (
                                                                    <div>
                                                                        {result.optionARating >= result.optionBRating ? (
                                                                            <>
                                                                                <div>😃{result.optionA}: {result.optionARating}%</div>
                                                                                <div>😞{result.optionB}: {result.optionBRating}%</div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>😃{result.optionB}: {result.optionBRating}%</div>
                                                                                <div>😞{result.optionA}: {result.optionARating}%</div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            case '5Stars':
                                                                return (
                                                                    <Rating name="result-rating" value={result.averageStarRating} precision={0.5} readOnly />
                                                                );
                                                            case 'agreeDisagree':
                                                                return (
                                                                    <div>
                                                                        {result.agreeRating >= result.disagreeRating ? (
                                                                            <>
                                                                                <div>😃Agree: {result.agreeRating}%</div>
                                                                                <div>😞Disagree: {result.disagreeRating}%</div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>😃Disagree: {result.disagreeRating}%</div>
                                                                                <div>😞Agree: {result.agreeRating}%</div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            case 'yesNo':
                                                                return (
                                                                    <div>
                                                                        {result.yesRating >= result.noRating ? (
                                                                            <>
                                                                                <div>😃Yes: {result.yesRating}%</div>
                                                                                <div>😞No: {result.noRating}%</div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>😃No: {result.noRating}%</div>
                                                                                <div>😞Yes: {result.yesRating}%</div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                );
                                                            case 'quality':
                                                                return (
                                                                    <div>
                                                                        <div>😃Excellent: {result.excellentRating}%</div>
                                                                        <div>🙂Good: {result.goodRating}%</div>
                                                                        <div>😐Average: {result.averageRating}%</div>
                                                                        <div>😔Bad: {result.badRating}%</div>
                                                                        <div>😞Terrible: {result.terribleRating}%</div>
                                                                    </div>
                                                                );
                                                            default:
                                                                return null;
                                                        }
                                                    })()}
                                                </TableCell>
                                                <TableCell align="justify" sx={{ wordWrap: "nowrap" }}>
                                                    {result.numVotes}
                                                </TableCell>
                                                <TableCell align="justify" sx={{ wordWrap: "nowrap" }}>
                                                    {formatDate(result.expiryDate.toDate())}</TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination

                                    component="div"
                                    count={sortedResults.length}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[]}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Card>

                        {/* {selectedRow && (
                            <Grid item xs={6}  >
                                <Card
                                    sx={{ marginTop: '4em', width: '1200px', marginLeft: "12px", overflow: 'auto' }}
                                >
                                    {showSelectForm ? (
                                        <Stack direction='row' spacing='10px' marginTop='.5em' marginBottom='.5em'>
                                            <Typography
                                                align="justify"
                                                sx={{
                                                    // marginLeft: "10px",
                                                    backgroundColor: "#F7F9F9",
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    color: "#7286D3",
                                                    display: "flex",
                                                    wordWrap: "break-word",
                                                    // marginTop: 2,
                                                    width: "700px",
                                                    paddingLeft: "5px",
                                                    // paddingRight: "5px",
                                                    alignItems: "justify"
                                                    // overflowY: "scroll",
                                                }}
                                            >
                                                {selectedRow.question}
                                            </Typography>
                                        </Stack>
                                    ) : null}
                                </Card>
                            </Grid>
                        )} */}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DBClosedPollResultsPrivate
